import React, { useEffect, useState } from "react";
import "../assets/css/App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReserverenMain from "./reserveren/ReserverenMain";
import Home from "./Home";
import Contact from "./contact/Contact";
import Login from "./auth/Login";
import Register from "./auth/Register";
import ForgotPassword from "./auth/ForgotPassword";
import Reset from "./auth/Reset";
import Nav from "./nav/Nav";
import { db, auth } from "../firebase";
import { useStateValue } from "../Stateprovider";
// import RecensiesShow from "./recensies/RecensiesShow";
import RecensieCreate from "./recensies/RecensieCreate";
import AccountSettings from "./account/AccountSettings";
import ReserverenAanvragen from "./reserveren/ReserverenAanvragen";
import ReserverenRedirect from "./reserveren/ReserverenRedirect";

import Betalen from "./reserveren/Betalen";
import Thanks from "./payment/Thanks";
import BottomBar from "./nav/BottomBar";

import Huisje from "./huis/Huisje";

import Faciliteiten from "./faciliteiten/Faciliteiten";
import PrakInfo from "./prakinfo/PrakInfo";
import Av from "./av/Av";
import { useHistory } from "react-router-dom";
import Omgeving from "./liggingOmgeving/Omgeving";
import Mail1 from "./mail/Mail1";
import Mail2 from "./mail/Mail2";
import Mail3 from "./mail/Mail3";
import Mail4 from "./mail/Mail4";
import Mail5 from "./mail/Mail5";

import MyBookings from "./mybookings/MyBookings";
import Cookies from "./cookies/Cookies";
import Privacy from "./cookies/Privacy";




function App() {
  const [{ user, bookDays }, dispatch] = useStateValue();
  const [loaded, setLoaded] = useState(false);



const API_KEY = ["AIzaSyA0LsP8s_UFjqOXB6YkwDheCdqHpkTwyh4"];

let fromLang = 'en';
let toLang = 'no'; // translate to norwegian
let text = 'something to translate';





function translateThis(text){
  let fromLang = 'en';
let toLang = 'nl'; // translate to norwegian

  let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
url += '&q=' + encodeURI(text);
url += `&source=${fromLang}`;
url += `&target=${toLang}`;
  fetch(url, { 
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  })
  .then(res => res.json())
  .then((response) => {
    console.log("response from google: ", response);
  })
  .catch(error => {
    console.log("There was an error with the translation request: ", error);
  });

}


  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "nl",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };
  // useEffect(() => {
  //   // translateThis('hello world')
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);
 

  useEffect(() => {
    // translateThis()
    
    let isSubscribed = true;
    if (isSubscribed) {
      auth.onAuthStateChanged((authUser) => {
        // console.log('the user is:',authUser);
        if (authUser) {
          setLoaded(true);
          dispatch({
            type: "SET_USER",
            user: authUser,
          });
        } else {
          setLoaded(true);
          dispatch({
            type: "SET_USER",
            user: null,
          });
        }
      });
    }
    return () => (isSubscribed = false);
  }, [user]);

  useEffect(() => {
    // console.log("hi", user)
    if (user) {
     
      db.collection("users")
        .where("userId", "==", user.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log( doc.data());
           
            dispatch({
              type: "SET_PROFILE",
              profile: doc.data(),
            });
          });
        })
        .catch((error) => console.log(error));
    }
  }, [user]);

  if (!user && !loaded) {
    <div style={{ flex: 1, justifyContent: "center" }}>Loading..</div>;
  }
  return (
    <Router>
       {/* <div id="google_translate_element"></div> */}
      <div className="app">
        {/* <div className="navbar_bg"></div> */}
       
        <Switch>
        
        
          <Route path="/mail1">
           
            <Mail1 bookDays={bookDays} />
          </Route>
          <Route path="/mail2">
           
            <Mail2 bookDays={bookDays} />
          </Route>
          <Route path="/mail3">
           
            <Mail3 bookDays={bookDays} />
          </Route>
          <Route path="/mail4">
           
            <Mail4 bookDays={bookDays} />
          </Route>
          <Route path="/mail5">
           
            <Mail5 bookDays={bookDays} />
          </Route>

          <Route path="/account">
          <Nav/>
          <div className="app_content">
            <AccountSettings />
            </div>
          </Route>

          {/* <Route path="/cookies">
          <Nav/>
          <div className="app_content">
            <Cookies/>
            </div>
          </Route> */}

          {/* <Route path="/privacy">
          <Nav/>
          <div className="app_content">
            <Privacy/>
            </div>
          </Route> */}

          <Route path="/liggingenomgeving">
          <Nav/>
         
            <Omgeving />
            
          </Route>

          <Route path="/faciliteiten">
          <Nav/>
          <div className="app_content">
            <Faciliteiten />
            </div>
          </Route>

          <Route path="/praktischeinfo">
          <Nav/>
          <div className="app_content">
           
            <PrakInfo />
            </div>
          </Route>

          {/* <Route path="/algemenevoorwaarden">
          <Nav/>
          <div className="app_content">
           
            <Av />
            </div>
          </Route> */}

          

          <Route path="/hethuisje">
          <Nav/>
          
            <Huisje />
            
          </Route>
          {/* <Route path="/betalen">
          <Nav/>
          <div className="app_content">
            <Betalen />
           </div>
          </Route> */}

          {/* <Route path="/reserveren/aanvragen">
          <Nav/>
          <div className="app_content">
            <ReserverenAanvragen />
            </div>
          </Route> */}

          {/* <Route path="/thanks">
          <Nav/>
          <div className="app_content">
            <Thanks />
            </div>
          </Route> */}

          <Route path="/reserveren">
          <Nav/>
          <div className="app_content">
            {/* <ReserverenMain /> */}
            <ReserverenRedirect/>
            </div>
          </Route>

          <Route path="/contact">
          <Nav/>
          <div className="app_content">
           
            <Contact />
            </div>
          </Route>

          {/* <Route exact path="/reviews/show">
           
            <RecensiesShow />
          </Route> */}

          <Route exact path="/reviews/create">
           
            <RecensieCreate />
          </Route>

          {/* <Route exact path="/mybookings">
          <Nav/>
          <div className="app_content">
            <MyBookings />
            </div>
          </Route> */}

          <Route exact path="/account-settings">
          <Nav/>
          <div className="app_content">
            <AccountSettings />
            </div>
          </Route>

          {/* <Route exact path="/login">
          <Nav/>
          <div className="app_content">
            <Login />
            </div>
          </Route>

          <Route exact path="/register">
          <Nav/>
          <div className="app_content">
            <Register />
            </div>
          </Route> */}

          {/* <Route exact path="/forgotpassword">
          <Nav/>
          <div className="app_content">
            <ForgotPassword />
            </div>
          </Route> */}

          <Route path="/">
          <Nav home/>
          
            <Home />
            
          </Route>

          {/* <Route path="/__/auth/action">
            <Reset />
          </Route> */}
         
        </Switch>
        <BottomBar />
      </div>
    </Router>
  );
}

export default App;
