import React, { useEffect, useState } from "react";
import "../../assets/css/Huisje.css";
import "../../assets/css/Omgeving.css";
import PeopleIcon from "@mui/icons-material/People";
import ShowerIcon from "@mui/icons-material/Shower";
import BedIcon from "@mui/icons-material/Bed";
import KitchenIcon from "@mui/icons-material/Kitchen";
import HvacIcon from "@mui/icons-material/Hvac";
import WifiIcon from "@mui/icons-material/Wifi";
import DeckIcon from "@mui/icons-material/Deck";
import PetsIcon from "@mui/icons-material/Pets";
import CircleIcon from "@mui/icons-material/Circle";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
// import "../../assets/css/SubItem.css";
import SubItem from "../SubItem/SubItem"
import { v4 as uuidv4 } from "uuid";
import { useStateValue } from "../../Stateprovider";

function Huisje(props) {
  const [{ lang }, dispatch] = useStateValue();
  
  const [data, setData] = useState([
    // {
    //   "desc": [
      
    //   <div className="subItem_desc">Kom naar Zeeland en geniet van de heerlijke streekproducten. Van verse vis tot biologische groenten. Bezoek de lokale marktjes en proef de smaken van het land. Of ga langs bij de boer en haal uw producten rechtstreeks van het land. Geniet van al het lekkers dat Zeeland te bieden heeft.</div>,
    //   <div className="subItem_desc">Kommen Sie nach Zeeland und genießen Sie die köstlichen regionalen Produkte. Von frischem Fisch bis hin zu biologischem Gemüse. Besuchen Sie die lokalen Märkte und probieren Sie die Aromen des Landes. Oder besuchen Sie einen Bauernhof und holen Sie sich Ihre Produkte direkt vom Feld. Genießen Sie alles, was Zeeland zu bieten hat.</div>,
    //   <div className="subItem_desc">Come to Zeeland and enjoy the delicious local products. From fresh fish to organic vegetables. Visit the local markets and taste the flavors of the land. Or visit a farm and get your products straight from the field. Enjoy all the goodness that Zeeland has to offer.</div>
    //   ],
    //   "title": ["Streekproducten", "Regionale Produkte", "Local products"],
    //   "fotos": [
    //   "/images/alma.jpg", "/images/bank2.jpg"
     
    //   ],
    //   "fotoIndex": 0,
    //   "bgc": "#BEC1D6", 
    //   "titcol": "#97A1CF", 
    //   "handImg": "/images/food.png"
    //   },
    {
      "desc": [
        <div className="subItem_desc">Wakker worden met het vakantiegevoel. In de hotelboxspring van Van der Valk kunt u na een warme stranddag of een mooie wandeltocht heerlijk ontspannen en slapen. De lichte slaapkamer met het originele balkenplafond en natuurlijke materialen is een zalige plek om te relaxen. Uitkijkend op de patio in mediterrane sferen is het goed rusten.</div>,
        <div className="subItem_desc">Aufwachen mit dem Urlaubsgefühl. In den Hotelboxspringbetten von Van der Valk können Sie nach einem warmen Tag am Strand oder einem schönen Spaziergang herrlich entspannen und schlafen. Das helle Schlafzimmer mit originalen Holzbalkendecken und natürlichen Materialien ist ein wunderbarer Ort zum Entspannen. Mit Blick auf die Terrasse im mediterranen Stil können Sie gut ausruhen.</div>,
        <div className="subItem_desc">Wake up with a vacation feeling. In the Van der Valk hotel box spring beds, you can relax and sleep wonderfully after a warm day at the beach or a beautiful walk. The light bedroom with original wooden beam ceilings and natural materials is a wonderful place to relax. Looking out over the patio in Mediterranean atmospheres, it's good to rest.</div>
      ],
      "title": [
        "Slaapkamer",
        "Schlafzimmer",
        "Bedroom"
      ],
      fotos: ["/images/slaapakmer-vakantiemethond-zeeland.jpg", "/images/slaapkamer-dishoek-zeeland.jpg", "/images/slaapkamer-vakantieaanzee-strand.jpg"],
      fotoIndex: 0,
      bgc: "#F4C272",
      titcol: "#FBB847",
      handImg: "/images/uitgeslapen.png",
    },

    {
      "desc": [
        <div className="subItem_desc">De verdieping is geheel ingericht als tweede slaapruimte met comfortabele bedden en opbergruimte. Hier is ook een fijne plek om de baby te verzorgen. De trap is zowel op de begane grond als op de verdieping voorzien van een veiligheidshekje.</div>,
        <div className="subItem_desc">Die Etage ist vollständig als zweiter Schlafbereich mit bequemen Betten und Stauraum eingerichtet. Hier gibt es auch einen schönen Ort, um das Baby zu pflegen. Die Treppe ist sowohl im Erdgeschoss als auch auf der Etage mit einem Sicherheitsgitter ausgestattet.</div>,
        <div className="subItem_desc">The upper floor is fully equipped as a second sleeping area with comfortable beds and storage space. There is also a nice place to care for the baby. The stairs are equipped with a safety gate on both the ground floor and the upper floor.</div>
        ],
        "title": ["Verdieping", "Etage", "Upper floor"],
      fotos: ["/images/slaapkamer-dishoek-vakantieaanzee.jpg", "/images/Slaapkamer-vakantie-dishoek.jpg","/images/slaapkamer-vakantiehuis-walcherenvakanties.jpg","/images/slaapkamer-walcherenvakanties-dishoek.jpg","/images/trap-walcheren-zeeland.jpg"],
      fotoIndex: 0,
      bgc: "#BEC1D6",
      titcol: "#97A1CF",
      handImg: "/images/gerust.png",
    },
    {
      desc: [
        <div className="subItem_desc">Met de intiem gelegen patio waant u zich in mediterrane sferen, en dat in Dishoek! Om uw kind of hond de vrijheid te gunnen en toch goed in de gaten te houden, is er een afsluitbaar tuinhekje. Op het comfortabele buitenmeubilair is het heerlijk luieren met een cocktail, ontbijten, lunchen en dineren, of zomaar even genieten van een rustig moment. Natuurlijk knipt u heerlijk geurende munt, rozemarijn of tijm om te gebruiken in uw vers bereide maaltijden.</div>,
        <div className="subItem_desc">Mit dem intimen Patio fühlen Sie sich in mediterraner Atmosphäre, und das in Dishoek! Um Ihrem Kind oder Hund Freiheit zu geben und trotzdem im Auge zu behalten, gibt es ein abschließbares Gartentor. Auf den komfortablen Gartenmöbeln können Sie mit einem Cocktail faulenzen, frühstücken, Mittag- oder Abendessen oder einfach nur einen ruhigen Moment genießen. Natürlich können Sie duftende Minze, Rosmarin oder Thymian schneiden und für Ihre frisch zubereiteten Mahlzeiten verwenden.</div>,
        <div className="subItem_desc">With the intimately located patio, you'll feel like you're in Mediterranean atmospheres, and that in Dishoek! To give your child or dog some freedom and still keep an eye on them, there is a lockable garden gate. On the comfortable outdoor furniture, you can relax with a cocktail, have breakfast, lunch, and dinner, or just enjoy a quiet moment. Of course, you can cut deliciously scented mint, rosemary or thyme to use in your freshly prepared meals.</div>
        ],
        title: ["Patio", "Patio", "Patio"],
      fotos: ["/images/terras-zeeland-strand.jpg", "/images/terras-zeeland-vakantie.jpg","/images/terras-vakantieaanzee-strandvakantie.jpg","/images/terras-vakantiehuis-dishoek.jpg","/images/terras-vakantiemethond-zonzeestrand.jpg"],
      fotoIndex: 0,
      bgc: "#D699B9",
      titcol: "#37919D",
      handImg: "/images/gwnmooi.png",
    },
    {
      desc: [<div className="subItem_desc">Zeg je Zeeland dan denkt u toch meteen aan mosselen? Eet u een oester, dan proeft u de zee. Schud uw smaakpapillen wakker! Aan de keukenapparaten ligt het niet: koelkast met vriesvak, vaatwasser, inductiekookplaat, waterkoker, combi-magnetron, een koffiezetapparaat, kookgerei en mooi serviesgoed. De keuken in landelijke stijl is gezellig en uitnodigend voor het bereiden van verrassende Zeeuwse ontdekkingen. De keuken is meer dan een plek waar alleen gekookt wordt. Hier komt u samen met familie en/of vrienden. </div>,
      <div className="subItem_desc">Wenn Sie an Zeeland denken, denken Sie sofort an Muscheln, oder? Wenn Sie eine Auster essen, schmecken Sie das Meer. Wecken Sie Ihre Geschmacksknospen auf! An der Küchenausstattung mangelt es nicht: Kühlschrank mit Gefrierfach, Geschirrspüler, Induktionskochfeld, Wasserkocher, Mikrowelle, eine Kaffeemaschine, Kochgeschirr und schönes Geschirr. Die Landhausküche ist gemütlich und lädt zum Zubereiten von überraschenden Entdeckungen aus Zeeland ein. Die Küche ist mehr als nur ein Ort zum Kochen. Hier kommen Sie mit Familie und/oder Freunden zusammen.</div>,
      <div className="subItem_desc">When you think of Zeeland, you immediately think of mussels, don't you? If you eat an oyster, you taste the sea. Wake up your taste buds! The kitchen appliances are not lacking: fridge with freezer compartment, dishwasher, induction hob, kettle, microwave, a coffee maker, cooking utensils, and beautiful tableware. The country-style kitchen is cozy and inviting for preparing surprising Zeeland discoveries. The kitchen is more than just a place to cook. Here you come together with family and/or friends.</div>],
      title: ["Keuken", "Küche", "Kitchen"],
      fotos: ["/images/keuken-zonzeestrand-vakantiemethond.jpg","/images/keuken-vakantiehuis-dishoek.jpg", "/images/keuken-zeeland-strand.jpg"],
      fotoIndex: 0,
      bgc: "#B2B1A5",
      titcol: "#63655D",
      handImg: "/images/insp.png",
    },
    {
      desc: [
        <div className="subItem_desc">Spoel zon, zand en zee van je af! De badkamer op de begane grond is een oase van rust. De regendouche geeft je het gevoel van een warme, zachte regenbui. En met de waskom kom je in de Mediterrane sferen. Aan de praktische kant van uw vakantie is ook gedacht met de wasmachine en wasdroger bij de hand. Heerlijk schoon.</div>,
        <div className="subItem_desc">Spülen Sie Sonne, Sand und Meer ab! Das Badezimmer im Erdgeschoss ist eine Oase der Ruhe. Die Regendusche gibt Ihnen das Gefühl eines warmen, sanften Regenschauers. Und mit dem Waschbecken fühlen Sie sich im mediterranen Stil. Auch an die praktische Seite Ihres Urlaubs wurde gedacht, mit Waschmaschine und Trockner zur Hand. Herrlich sauber.</div>,
        <div className="subItem_desc">Rinse off the sun, sand, and sea! The bathroom on the ground floor is an oasis of calm. The rain shower gives you the feeling of a warm, soft rain shower. And with the washbasin, you are in the Mediterranean atmosphere. The practical side of your vacation has also been taken care of, with a washing machine and dryer at hand. Wonderfully clean.</div>
        ],
        title: ["Badkamer", "Badezimmer", "Bathroom"],
      fotos: ["/images/badkamer-strandvakantie-walcheren.jpg", "/images/badkamer-vakantieaanzee-zonzeestrand.jpg"],
      fotoIndex: 0,
      bgc: "#009E82",
      titcol: "#57B5A2",
      handImg: "/images/regen.png",
    },
  ]);

  const [leftIcons, setLeftIcons] = useState([
    {
    icon: <PeopleIcon fontSize="large" />,
    text: ["4 personen", "4 Personen", "4 persons"]
    },
    {
    icon: <ShowerIcon fontSize="large" />,
    text: ["Badkamer", "Badezimmer", "Bathroom"]
    },
    {
    icon: <MeetingRoomIcon fontSize="large" />,
    text: ["2 slaapkamers", "2 Schlafzimmer", "2 bedrooms"]
    },
    {
    icon: <BedIcon fontSize="large" />,
    text: ["Bedden: 1 dubbel, 2 enkel", "Betten: 1 Doppelbett, 2 Einzelbetten", "Beds: 1 double, 2 single"]
    },
    {
    icon: <KitchenIcon fontSize="large" />,
    text: ["Luxe open keuken", "Luxuriöse offene Küche", "Luxury open kitchen"]
    },
    {
    icon: <HvacIcon fontSize="large" />,
    text: ["Hvac", "Hvac", "Hvac"]
    },
    {
    icon: <WifiIcon fontSize="large" />,
    text: ["Gratis wifi", "Kostenloses WLAN", "Free wifi"]
    },
    {
    icon: <DeckIcon fontSize="large" />,
    text: ["Patio", "Terrasse", "Patio"]
    },
    {
    icon: <PetsIcon fontSize="large" />,
    text: ["2 honden max", "Max. 2 Hunde erlaubt", "Max. 2 dogs allowed"]
    },
    ]);

    

  const [topText1,setTopText]=useState([
    " Ons vakantiehuisje Dishoek bij zee biedt u gezelligheid, comfort en gastvrijheid. Hier maakt u van ons huisje voor een tijdje uw thuis. Met een boek op de bank, de hond aan uw voeten. Met een drankje op de intieme patio, gezellige gesprekken in huis aan de eettafel. Een warme douche en een comfortabel bed.",
    "Unser Ferienhaus in Dishoek am Meer bietet Gemütlichkeit, Komfort und Gastfreundschaft. Hier machen Sie unser Haus für eine Weile zu Ihrem Zuhause. Mit einem Buch auf dem Sofa und Ihrem Hund zu Ihren Füßen. Mit einem Drink auf der intimen Terrasse und gemütlichen Gesprächen am Esstisch. Eine warme Dusche und ein bequemes Bett.",
    "Our holiday home in Dishoek by the sea offers coziness, comfort, and hospitality. Here you make our home your home for a while. With a book on the couch, the dog at your feet. With a drink on the intimate patio, cozy conversations inside at the dining table. A warm shower and a comfortable bed."

  ])
  const [topText2,setTopText2]=useState([
    "Ervaar het zelf. Om te stranden.",
    "Erleben Sie es selbst. Zum Strand.",
    "Experience it yourself. To the beach."
  ])

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  function updateFotoIndex(indexArr, indexFoto) {
    let newArr = [...data];
    newArr[indexArr].fotoIndex = indexFoto;
    setData(newArr);
  }
  return (
    <div className="huis">
      <div className="huis_first" style={{ background: `linear-gradient(to left, #D6E8C6 60%, white 40%) ` }}>
        <div className="huis_first_1">
          <div className="huis_first_1_l">
            <div className="huis_l_con">
              {leftIcons.map((doc) => (
                <div key={uuidv4()} className="huis_l_elm">
                  <div className="huis_l_img">{doc.icon}</div>
                  <div className="huis_l_text">{doc.text[lang]}</div>
                </div>
              ))}
            </div>
          </div>
          <div key={uuidv4()} className="huis_first_1_r">
            <div  className="huis_first_1_r_bottom">
              {topText1[lang]}
              <br></br>
              <br></br>
              <p className="huisje_scroll_link" onClick={() => document.getElementById("0").scrollIntoView({ behavior: "smooth" })}>
              {topText2[lang]}
              </p>
            </div>
          </div>
        </div>
        
      </div>
      
      <SubItem data1={data}/>
    </div>
  );
}

export default Huisje;
