import React, { useEffect, useState } from "react";
import "../../assets/css/Huisje.css";
import "../../assets/css/Omgeving.css";
import "../../assets/css/SubItem.css";
import { useStateValue } from "../../Stateprovider";
import CircleIcon from "@mui/icons-material/Circle";
// import "leaflet/dist/leaflet.css";
import L, { LatLngExpression } from "leaflet";
import { v4 as uuidv4 } from "uuid";
import SubItem from "../SubItem/SubItem"
import PeopleIcon from "@mui/icons-material/People";
function Huisje(props) {
  const [{ lang }, dispatch] = useStateValue();
  const [linkIcons, setLinkIcons] = useState([
    {
    icon: <img style={{width:"20px",height:"20px"}} src="/images/njans.png"></img>,
    text: [<div onClick={()=>window.open("https://www.neeltjejans.nl/")}>Neeltje Jans</div>,
    <div onClick={()=>window.open("https://www.neeltjejans.nl/")}>Neeltje Jans</div>,
    <div onClick={()=>window.open("https://www.neeltjejans.nl/")}>Neeltje Jans</div>]
    },
    {
      icon: <img style={{width:"20px",height:"20px"}} src="/images/m.png"></img>,
      text: [<div onClick={()=>window.open("https://www.muzeeum.nl")}>MuZEEum</div>,
      <div onClick={()=>window.open("https://www.muzeeum.nl")}>MuZEEum</div>,
      <div onClick={()=>window.open("https://www.muzeeum.nl")}>MuZEEum</div>
    ]
      },
      {
        icon: <img style={{width:"20px",height:"20px"}} src="/images/rond.png"></img>,
        text: [<div onClick={()=>window.open("https://www.northseaport.com/rondvaart-vlissingen")}>Rondvaart Vlissingen</div>,
        <div onClick={()=>window.open("https://www.northseaport.com/rondvaart-vlissingen")}>Vlissingen-Kreuzfahrt</div>,
        <div onClick={()=>window.open("https://www.northseaport.com/rondvaart-vlissingen")}>Vlissingen cruise</div>
      ]
        },
        {
          icon: <img style={{width:"20px",height:"20px"}} src="/images/west.png"></img>,
          text: [<div onClick={()=>window.open("https://westerscheldeferry.nl/")}>Overvaren naar Zeeuws-Vlaanderen met de fiets of lopend</div>,
          <div onClick={()=>window.open("https://westerscheldeferry.nl/")}>Überqueren Sie Zeeuws-Vlaanderen mit dem Fahrrad oder zu Fuß</div>,
          <div onClick={()=>window.open("https://westerscheldeferry.nl/")}>Cross over to Zeeuws-Vlaanderen by bike or on foot</div>
        ]
          },
        {
          icon: <img style={{width:"20px",height:"20px"}} src="/images/wand.png"></img>,
          text: [<div onClick={()=>window.open("https://www.reisroutes.nl/stadswandelingen/zeeland-nederland/middelburg-wandelen/")}>Stadswandeling Middelburg</div>,
          <div onClick={()=>window.open("https://www.reisroutes.nl/stadswandelingen/zeeland-nederland/middelburg-wandelen/")}>Stadtrundgang Middelburg</div>,
          <div onClick={()=>window.open("https://www.reisroutes.nl/stadswandelingen/zeeland-nederland/middelburg-wandelen/")}>City walk Middelburg</div>
        ]
          },
          {
            icon: <img style={{width:"20px",height:"20px"}} src="/images/mus.png"></img>,
            text: [<div onClick={()=>window.open("https://www.museumschip-mercuur.nl/")}>Museumschip</div>,
            <div onClick={()=>window.open("https://www.museumschip-mercuur.nl/")}>Museumschip</div>,
            <div onClick={()=>window.open("https://www.museumschip-mercuur.nl/")}>Museumschip</div>
          ]
            },
            {
              icon: <img style={{width:"20px",height:"20px"}} src="/images/room.png"></img>,
              text: [<div onClick={()=>window.open("https://www.hofdomburg.nl/het-park/zwembad")}>Zwembad Domburg</div>,
              <div onClick={()=>window.open("https://www.hofdomburg.nl/het-park/zwembad")}>Zwembad Domburg</div>,
              <div onClick={()=>window.open("https://www.hofdomburg.nl/het-park/zwembad")}>Zwembad Domburg</div>
            ]
              }
    ]);

    const [linkIconskoel, setLinkIconsKoel] = useState([
      {
      icon: <img style={{width:"20px",height:"20px"}} src="/images/spar.png"></img>,
      text: [<div onClick={()=>window.open("https://www.spar.nl/winkels/spar-enjoy-vebenabos-339/")}>Spar</div>,
      <div onClick={()=>window.open("https://www.spar.nl/winkels/spar-enjoy-vebenabos-339/")}>Spar</div>,
      <div onClick={()=>window.open("https://www.spar.nl/winkels/spar-enjoy-vebenabos-339/")}>Spar</div>]
      },
      {
        icon: <img style={{width:"20px",height:"20px"}} src="/images/vit.png"></img>,
        text: [<div onClick={()=>window.open("https://vitaminehoekdekragge.nl/")}>Vitaminehoek</div>,
        <div onClick={()=>window.open("https://vitaminehoekdekragge.nl/")}>Vitaminecke</div>,
        <div onClick={()=>window.open("https://vitaminehoekdekragge.nl/")}>Vitamin corner</div>
      ]
        },
        {
          icon: <img style={{width:"20px",height:"20px"}} src="/images/f.png"></img>,
          text: [<div onClick={()=>window.open("https://www.groenteschuurtje.com/")}>Boerderijwinkel</div>,
          <div onClick={()=>window.open("https://www.groenteschuurtje.com/")}>Hofladen</div>,
          <div onClick={()=>window.open("https://www.groenteschuurtje.com/")}>Farm shop</div>
        ]
          },
          {
            icon: <img style={{width:"20px",height:"20px"}} src="/images/k.png"></img>,
            text: [<div onClick={()=>window.open("https://schellach.nl/")}>Kaasboerderij</div>,
            <div onClick={()=>window.open("https://schellach.nl/")}>Käsebauernhof</div>,
            <div onClick={()=>window.open("https://schellach.nl/")}>Cheese farm</div>
          ]
            },
          {
            icon: <img style={{width:"20px",height:"20px"}} src="/images/aldi.png"></img>,
            text: [<div onClick={()=>window.open("https://www.aldi.nl/aldi-vernieuwt/koudekerke.html")}>Aldi</div>,
            <div onClick={()=>window.open("https://www.aldi.nl/aldi-vernieuwt/koudekerke.html")}>Aldi</div>,
            <div onClick={()=>window.open("https://www.aldi.nl/aldi-vernieuwt/koudekerke.html")}>Aldi</div>
          ]
            },
            {
              icon: <img style={{width:"20px",height:"20px"}} src="/images/jum.png"></img>,
              text: [<div onClick={()=>window.open("https://www.jumbo.com/winkel/koudekerke/jumbo-koudekerke-duinstraat")}>Jumbo</div>,
              <div onClick={()=>window.open("https://www.jumbo.com/winkel/koudekerke/jumbo-koudekerke-duinstraat")}>Jumbo</div>,
              <div onClick={()=>window.open("https://www.jumbo.com/winkel/koudekerke/jumbo-koudekerke-duinstraat")}>Jumbo</div>
            ]
              },
              
      ]);
  const [data, setData] = useState([
    {
      "title": ["Winkelen in Zeeland", "Einkaufen in Zeeland", "Shopping in Zeeland"],
      "desc": [
      <div className="subItem_desc">Een dagje winkelen hoort bij een vakantie aan de Zeeuwse kust. Elke plaats heeft wel een souvenirwinkel of een winkel met streekproducten. Er zijn kleine boetiekjes en grotere winkelketens. Struinen door de winkelstraten, genieten van de gezellige sfeer, van winkelstraat naar één van de terrassen voor een lunchpauze. In Zeeland kun je van alles vinden.</div>,
      <div className="subItem_desc">Ein Einkaufstag gehört zum Urlaub an der Zeeländischen Küste. Jeder Ort hat einen Souvenirladen oder ein Geschäft mit regionalen Produkten. Es gibt kleine Boutiquen und größere Einzelhandelsketten. Bummeln Sie durch die Einkaufsstraßen, genießen Sie die gemütliche Atmosphäre und wechseln Sie von einer Einkaufsstraße zu einer der Terrassen für eine Mittagspause. In Zeeland finden Sie alles.</div>,
      <div className="subItem_desc">A day of shopping is part of a vacation on the Zeeland coast. Every place has a souvenir shop or a store with regional products. There are small boutiques and larger retail chains. Stroll through the shopping streets, enjoy the cozy atmosphere and move from one shopping street to one of the terraces for a lunch break. In Zeeland you can find everything.</div>
      ],
      "fotos": ["/images/dishoek4.jpg"],
      "fotoIndex": 0,
      "bgc": "#C5E4E9", 
      "titcol": "#87CEE2",
      "handImg": "/images/kids.png"
      },
      {
        "desc": [
        <div className="subItem_desc"></div>,
        <div className="subItem_desc"></div>,
        <div className="subItem_desc"></div>
        ],
        "title": ["Koelkast vullen", "Kühlschrank füllen", "Fill refrigerator"],
        "fotos": ["/images/keuk.jpg"
       
        ],
        "fotoIndex": 0,
        "bgc": "#FEDFA1", 
        "titcol": "#FBB847", 
        "handImg": "/images/ewater.png",
        "links":linkIconskoel
        },
      
      
      
      
  
      {
        "desc": [
        <div className="subItem_desc">In Zeeland is er áltijd een strand te vinden waar de wind precies goed staat voor het beoefenen van watersport. Of je nu gaat snorkelen, kiten of zeilen. Voor alle niveaus en watersporten is er een goede plek in Zeeland; van rustige wateren zoals het Veerse Meer tot de uitdagende open Noordzee.</div>,
        <div className="subItem_desc">In Zeeland gibt es immer einen Strand, an dem der Wind genau richtig für den Wassersport steht. Egal, ob Sie schnorcheln, kiten oder segeln möchten. Für alle Niveaus und Wassersportarten gibt es in Zeeland einen guten Platz; von ruhigen Gewässern wie dem Veerse Meer bis zur herausfordernden offenen Nordsee.</div>,
        <div className="subItem_desc">In Zeeland there is always a beach where the wind is just right for practicing water sports. Whether you want to snorkel, kite or sail. There is a good spot in Zeeland for all levels and water sports; from calm waters such as the Veerse Meer to the challenging open North Sea.</div>
        ],
        "title": ["Watersport", "Wassersport", "Water sports"],
        "fotos": ["/images/dishoek5.jpg"
       
        ],
        "fotoIndex": 0,
        "bgc": "#FEDFA1", 
        "titcol": "#FBB847", 
        "handImg": "/images/ewater.png"
        },
        {
          "desc": [
          <div className="subItem_desc">Met de kinderen is er genoeg te beleven in Zeeland. Ontdek de Deltawerken of geniet van de lekkerste pannenkoeken. Ontdek Walcheren in miniatuur terwijl de kinderen genieten in een indoor speeltuin of het pretpark.</div>,
          <div className="subItem_desc">Mit den Kindern gibt es in Zeeland genug zu erleben. Entdecken Sie die Deltawerke oder genießen Sie die leckersten Pfannkuchen. Entdecken Sie Walcheren im Miniaturformat, während die Kinder in einem Indoor-Spielplatz oder im Vergnügungspark Spaß haben.</div>,
          <div className="subItem_desc">There is plenty to do with the children in Zeeland. Discover the Delta Works or enjoy the tastiest pancakes. Discover Walcheren in miniature while the children have fun in an indoor playground or amusement park.</div>
          ],
          "title": ["Dagje uit met de kinderen", "Tagesausflug mit den Kindern", "Day out with the children"],
          "fotos": [
          "/images/dishoek7.jpg",
         
          ],
          "fotoIndex": 0,
          "bgc": "#BEC1D6",
          "titcol": "#97A1CF",
          "handImg": "/images/kids.png", 
          "links":linkIcons
          },
          {
            "desc": [
            <div className="subItem_desc">Zeeuwen bin zuunig op hun landschap, cultuur en eigenheid. En daar kun je als wandelaar volop van meegenieten. Tientallen wandelroutes leiden je langs de onontdekte plekken, stille landpaden en unieke natuurgebieden. Trek je wandelschoenen aan en ontdek de pareltjes en de leukste ommetjes. Ook met kinderen is hier genoeg te beleven!</div>,
            <div className="subItem_desc">Die Zeelanders sind stolz auf ihre Landschaft, Kultur und Eigenständigkeit. Und als Wanderer können Sie davon in vollen Zügen profitieren. Dutzende von Wanderwegen führen Sie zu unentdeckten Orten, ruhigen Landstraßen und einzigartigen Naturschutzgebieten. Ziehen Sie Ihre Wanderschuhe an und entdecken Sie die Juwelen und die schönsten Spaziergänge. Auch mit Kindern gibt es hier viel zu erleben!</div>,
            <div className="subItem_desc">Zeelanders are proud of their landscape, culture and individuality. And as a hiker, you can fully enjoy that. Dozens of hiking trails lead you to undiscovered places, quiet country roads and unique nature reserves. Put on your hiking boots and discover the gems and the nicest walks. There is also plenty to do here with children!</div>
            ],
            "title": ["Wandelen in Zeeland", "Wandern in Zeeland", "Hiking in Zeeland"],
            "fotos": [
            "/images/dishoek22.jpg",
           
            ],
            "fotoIndex": 0,
            "bgc": "#D699B9",
            "titcol": "#863B67",
            "handImg": "/images/uniek.png", 
            },
            {
              "desc": [
              <div className="subItem_desc">Fietsen in Zeeland is heerlijk in ieder jaargetijde! Overal in de provincie zijn er routes waar je zo langs het water óf door het Zeeuwse achterland kunt fietsen. Al trappend zie je de schepen voorbij varen. De frisse zeewind strijkt door je haren. Met ruim 35 fietsroutes valt er genoeg te kiezen!</div>,
              <div className="subItem_desc">Fahrradfahren in Zeeland ist zu jeder Jahreszeit wunderschön! Überall in der Provinz gibt es Routen, entlang des Wassers oder durch das zeeländische Hinterland. Während Sie treten, sehen Sie die Schiffe vorbeifahren. Die frische Meeresbrise weht durch Ihre Haare. Mit über 35 Fahrradrouten gibt es genug zu wählen!</div>,
              <div className="subItem_desc">Cycling in Zeeland is wonderful in every season! Everywhere in the province there are routes where you can cycle along the water or through the Zeeland countryside. As you pedal, you see the ships passing by. The fresh sea breeze brushes through your hair. With over 35 cycling routes, there's plenty to choose from!</div>
              ],
              "title": ["Fietsen in Zeeland", "Fahrradfahren in Zeeland", "Cycling in Zeeland"],
              "fotos": [
              "/images/dishoek3.jpg",
             
              ],
              "fotoIndex": 0,
              "bgc": " #B2B1A5",
              "titcol": "#63655D",
              "handImg": "/images/fietsen.png"
              },
              {
                "desc": [
                  <div className="subItem_desc">Kunst en cultuur is overal te vinden in Zeeland. Van kunstwerken in musea tot muurschilderingen op straat. Al wandelend door de straten proef je een en al cultuur. Overal zijn monumenten en erfgoed te vinden, ook in bijzondere musea. Kunstenaars die worden geïnspireerd door zon, zee en de schitterende lucht, zijn hier op de juiste plek. Één ding is zeker; je culturele hart kun je hier ophalen.</div>,
                  <div className="subItem_desc">Kunst und Kultur sind überall in Zeeland zu finden. Von Kunstwerken in Museen bis hin zu Wandgemälden auf der Straße. Beim Spaziergang durch die Straßen spürt man Kultur in jeder Ecke. Überall gibt es Denkmäler und Kulturerbe, auch in besonderen Museen. Künstler, die von Sonne, Meer und dem herrlichen Himmel inspiriert werden, sind hier am richtigen Ort. Eins ist sicher; hier kann man sein kulturelles Herz höher schlagen lassen.</div>,
                  <div className="subItem_desc">Art and culture can be found everywhere in Zeeland. From artworks in museums to murals on the street. Walking through the streets, you can taste culture in every corner. There are monuments and heritage sites everywhere, including in special museums. Artists who are inspired by the sun, sea, and the magnificent sky are in the right place here. One thing is certain; you can satisfy your cultural heart here.</div>
                ],
                "title": ["Kunst en cultuur", "Kunst und Kultur", "Art and culture"],
                "fotos": [
                  "/images/dishoek6.jpg",
                  
                ],
                "fotoIndex": 0,
                "bgc": "#E88683",
                "titcol": "#AB4357",
                "handImg": "/images/kunst.png"
              }
              
  ]);
  const [topText,setTopText]=useState([
    <div>Uw vakantiehuisje ligt op slechts 600 meter van de strandopgang in Dishoek. Eenmaal op het prachtige zuidstrand, varen grote zeeschepen varen vlak aan de waterlijn voorbij. Vanaf het hoge duinpad langs de kust, soms wel 50 meter hoog, heeft u een breed uitzicht over de zee en het Walcherse land.
    <br></br>
    <br></br> In Zeeland is het, in ieder seizoen, heerlijk om uw dagen door te brengen. Of u nu houdt van een bezoek aan historische steden en dorpen, een museumbezoek, van watersport of liever het Zeeuwse landschap verkent, het is er allemaal voor u om te ontdekken.
    <br></br>
    <br></br> Maak kennis met de Zeeuwse natuur. Daagt u uzelf liever uit op het water of wilt u genieten van de prachtige zandstranden? Dat kan, want waar u ook bent: in Zeeland heb je water rondom en staat u altijd binnen 15 minuten aan open water.
    <br></br>
    {/* <br></br> Zeeland. Onvoorstelbaar mooi. */}
    </div>,
    <div>Ihr Ferienhaus liegt nur 600 Meter vom Strandzugang in Dishoek entfernt. Einmal am wunderschönen Südstrand angekommen, fahren große Seeschiffe direkt an der Wasserlinie vorbei. Vom hohen Dünenpfad entlang der Küste, manchmal bis zu 50 Meter hoch, haben Sie einen weiten Blick über das Meer und das Walcherische Land.
    <br></br>
    <br></br> In Zeeland ist es in jeder Jahreszeit wunderbar, Ihre Tage zu verbringen. Ob Sie historische Städte und Dörfer besuchen, ein Museum besichtigen, Wassersport betreiben oder lieber die Zeeländische Landschaft erkunden möchten, alles steht Ihnen zur Entdeckung bereit.
    <br></br>
    <br></br> Lernen Sie die Natur von Zeeland kennen. Möchten Sie sich lieber auf dem Wasser herausfordern oder die wunderschönen Sandstrände genießen? Das können Sie, denn wo immer Sie sind: In Zeeland haben Sie Wasser ringsum und sind immer innerhalb von 15 Minuten von offenem Wasser entfernt.
    <br></br>
    {/* <br></br> Zeeland. Unglaublich schön. */}
    </div>
    ,
    <div>Your holiday home is located only 600 meters from the beach entrance in Dishoek. Once on the beautiful south beach, large sea ships sail past just off the waterline. From the high dune path along the coast, sometimes as high as 50 meters, you have a wide view over the sea and the Walcheren countryside.
    <br></br>
    <br></br> In Zeeland, it is wonderful to spend your days in every season. Whether you like to visit historical cities and villages, go to museums, practice water sports or prefer to explore the Zeeland countryside, it's all there for you to discover.
    <br></br>
    <br></br> Get to know the Zeeland nature. Do you prefer to challenge yourself on the water or enjoy the beautiful sandy beaches? You can do that, because wherever you are: in Zeeland you have water all around and you are always within 15 minutes from open water.
    <br></br>
    {/* <br></br> Zeeland. Incredibly beautiful. */}
    </div>
  ])
  function updateFotoIndex(indexArr, indexFoto) {
    let newArr = [...data];
    newArr[indexArr].fotoIndex = indexFoto;
    setData(newArr);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(window.innerWidth);
    var map = L.map("map").setView([51.476010087993686, 3.5232498192384063], 16);

    L.tileLayer("https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}", {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: "mapbox/streets-v11",
      tileSize: 512,
      zoomOffset: -1,
      accessToken: "pk.eyJ1IjoicGlldGcxMjM0IiwiYSI6ImNrd2RocDFidzQ0eHUzMHJveGNmY2UyYnAifQ.r2rclJ70-a9SMFZRZQ8H8w",
    }).addTo(map);

    L.marker([51.47739303001958, 3.5230313472777963]).addTo(map).bindPopup("Uw verblijf").openPopup();
    L.marker([51.4735409, 3.5212624]).addTo(map).bindPopup("Eetcafé Pension 'Duinlust'<br>Dishoek 18, 4371 NS Koudekerke, Nederland Tel: 0031(0)118-551534, Vanaf 1 april 7 dagen per week geopend van 09:30 tot 21:00. In laagseizoen woensdag tot en met zondag geopend, vanaf 09:30 tot 21:00").openPopup();
    L.marker([51.4740632, 3.5209712]).addTo(map).bindPopup("Brasserie Het Strandhuys (Grand Café Blur)").openPopup();
    L.marker([51.4739691, 3.5212839]).addTo(map).bindPopup("Andrea’s Brasserie").openPopup();
    L.marker([51.4724814, 3.5173843]).addTo(map).bindPopup("Strandpaviljoen Kaapduin").openPopup();
    L.marker([51.466688138466175, 3.527498017752112]).addTo(map).bindPopup("Spar Dishoek.maandag07:00–19:00. dinsdag07:00–19:00. woensdag07:00–19:00. donderdag07:00–19:00. vrijdag07:00–19:00. zaterdag07:00–19:00. zondag08:00–18:00").openPopup();

    
  }, []);

  return (
    <div className="huis">
      <div className="omgeving_first" style={{ background: `linear-gradient(to left, #D6E8C6 60%, white 40%) ` }}>
        <div className="omgeving_first_1" >
          <div className="omgeving_first_1_l" >
            OVER DISHOEK
          </div>
          <div className="omgeving_first_1_r">
            {/* <div className="omgeving_first_1_r_top">
              <img src="/images/handWritten.png" className="omgeving_first_1_r_top_img" style={{ height: "130px", marginBottom: "5%" }}></img>
            </div> */}
            <div className="omgeving_first_1_r_bottom" >
             {topText[lang]}
            </div>
          </div>
        </div>
        <div className="omgeving_first_imgCon" style={{ marginBottom: "100px" }}>
          <img src="/images/dishoek1.jpg" className="omgeving_first_img"></img>
        </div>
      </div>
      <SubItem data1={data}/>
      <div className="subItem" style={{ background: `linear-gradient(to left, #C5E4E9 60%, white 40%) ` }}>
        <div className="subItem_con">
          <div className="subItem_imgCon subItem_map" id="map"></div>
          <div className="subItem_bottom">
            <div className="subItem_textCon">
              <div style={{ color: "#009E82" }} className="subItem_title">
                Culinaire verrassingen in Dishoek
              </div>
              <div className="subItem_desc">Zeeland is een rijke voorraadkast als het om eten gaat. Dat kan ook bijna niet anders met zoveel streekproducten, traditionele gerechten én Michelinsterren. De zilte zaligheden komen bijna rechtstreeks uit de zee, op je bord. Verser kan niet. Maar behalve producten uit de zee zijn hier ook andere heerlijke streekproducten</div>
            </div>
            {/* <div className="subItem_bottom_moreCon">
              <img className="subItem_bottom_more" src="/images/zlig.png"></img>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Huisje;
