import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyA0LsP8s_UFjqOXB6YkwDheCdqHpkTwyh4",
  authDomain: "dishoekhuisje.firebaseapp.com",
  projectId: "dishoekhuisje",
  storageBucket: "dishoekhuisje.appspot.com",
  messagingSenderId: "275980278645",
  appId: "1:275980278645:web:b3ab82b507aaaa576bd13c",
  measurementId: "G-0L9YDETZNP",
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();
const functions = firebase.functions();

export { db, auth, functions };
