import React from "react";
import "../../assets/css/BottomBar.css";
import { Link, useHistory } from "react-router-dom";

function BottomBar(props) {
  const history = useHistory();
  return (
    <div className="bb">
      <div className="bb_row">
        <div className="bb_col">
          <div className="bb_col_row">
            <div className="bb_col_tit">Reserveren</div>
            <Link to="/reserveren">
              <div className="bb_col_elm">Reserveren</div>
            </Link>
            <Link to="/hethuisje">
              <div className="bb_col_elm">Uw verblijf</div>
            </Link>

            <Link to="/faciliteiten">
              <div className="bb_col_elm">Faciliteiten</div>
            </Link>
          </div>
        </div>

        {/* <div className="bb_col">
          <div className="bb_col_row">
            <div className="bb_col_tit">Service</div>
            <Link to="/algemenevoorwaarden">
              <div className="bb_col_elm">Algemene voorwaarden</div>
            </Link>
            <Link to="/">
              <div className="bb_col_elm">Privacy</div>
            </Link>
            <Link to="/">
              <div className="bb_col_elm">Betaalmethoden</div>
            </Link>
            <Link to="/">
              <div className="bb_col_elm">Serice & contact</div>
            </Link>
          </div>
        </div> */}

        <div className="bb_col">
          <div className="bb_col_row">
            <div className="bb_col_tit">Contact</div>

            <div style={{ fontWeight: "bold" }} className="bb_col_elm">
              Dishoek bij zee
            </div>
            <div className="bb_col_elm">Westerscheldelaan 17A</div>
            <div className="bb_col_elm">4371 PP Dishoek</div>
            <div className="bb_col_elm">E: info@dishoekbijzee.nl</div>
            <div className="bb_col_elm">T: +31 6 27382501</div>
          </div>
        </div>
      </div>
      {/* <div className="bb_row_bottom">
      
        <div onClick={()=>history.push("/privacy")} className="bb_row_elm_bottom">Privacy</div>
        
        <div onClick={()=>history.push("/cookies")} className="bb_row_elm_bottom">Cookies</div>
        
        
        <div className="bb_row_elm_bottom">Algemene voorwaarden</div>
      </div> */}
    </div>
  );
}

export default BottomBar;
