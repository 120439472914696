import React, { useState,useEffect } from "react";
import * as ReactDOM from 'react-dom';
import "../../assets/css/Faciliteiten.css";
import CheckIcon from "@mui/icons-material/Check";
import { useStateValue } from "../../Stateprovider";
function Faciliteiten(props) {
  const [{ lang }, dispatch] = useStateValue();
  const [data, setData] = useState([
    {
      title: ["Algemeen", "Allgemein", "General"],
      elms: [
      ["Wifi", "Gratis parkeren op eigen terrein", "Oplaadpunt elektrische auto", "Honden toegestaan (max. 2)", "Kindveilige stopcontacten", "Airco en verwarming", "Eigen huissleutel", "Factuur wordt verstrekt", "Niet roken accommodatie"],
      ["WLAN", "Kostenlose Parkplätze auf dem Gelände", "Ladestation für Elektroautos", "Hunde erlaubt (max. 2)", "Kindersichere Steckdosen", "Klimaanlage und Heizung", "eigener Hausschlüssel", "Rechnung wird bereitgestellt", "Nichtraucherunterkunft"],
      ["Wifi", "Free parking on site", "Electric vehicle charging point", "Pets allowed (max. 2)", "Childproof sockets", "Air conditioning and heating", "Own house key", "Invoice provided", "Non-smoking accommodation"]
      ]
      },
      {
        "title": ["Woonkamer", "Wohnzimmer", "Living room"],
        "elms": [
        ["Tv met kabelzenders", "Airco en verwarming", "Bordspellen/puzzels","Netflix inbegrepen"],
        ["Fernseher mit Kabelkanälen", "Klimaanlage und Heizung", "Brettspiele/Puzzles","Netflix inklusive"],
        ["TV with cable channels", "Air conditioning and heating", "Board games/puzzles","Netflix included"]
        ]
        },
        {
          "title": ["Keuken", "Küche", "Kitchen"],
          "elms": [    [" Afzuigkap", "Filter koffiezetapparaat","Nespresso apparaat", "Koel-vries combinatie", "Inductie kookplaat", "Vaatwasser", "Quooker", "Combimagnetron"],
            ["Abzugshaube", "Filterkaffeemaschine","Nespresso machine", "Kühl-Gefrierkombination", "Induktionskochfeld", "Geschirrspüler", "Quooker", "Kombimikrowelle"],
            ["Extractor hood", "Filter coffee maker","Nespresso-Maschine", "Fridge-freezer combination", "Induction hob", "Dishwasher", "Quooker", "Combination microwave"]
          ]
        },
        
        {
          "title": ["Slaapkamer", "Schlafzimmer", "Bedroom"],
          "elms": [
          ["Boxspring bedden", "Opladen USB","Opbergruimte"],
          ["Boxspringbetten", "Aufladen über USB","Speicherplatz"],
          ["Boxspring beds", "Charging USB","Storage space"]
          ]
          },
          {
            "title": ["Wasmachinekast", "Waschmaschinenschrank", "Washing machine cabinet"],
            "elms": [
            ["Dyson stofzuiger", "Was/drogercombinatie","Wasmiddelen"],
            ["Dyson Staubsauger", "Waschmaschine/Trockner-Kombination", "Waschmittel"],
            ["Dyson vacuum cleaner", "Washing machine/dryer combination", "Laundry detergents"]
            
            ]
            },
          {
            "title": ["Badkamer", "Badezimmer", "Bathroom"],
            "elms": [
            ["Inloopdouche met deur", "Wasmeubel", "Wc","Regendouche en handdouche", "Infrarood verwarming"],
            ["Begehbare Dusche mit Tür", "Waschtisch", "WC","Regendusche und Handbrause", "Infrarotheizung"],
            ["Walk-in shower with door", "Washbasin", "Toilet","Rain shower and hand shower", "Infrared heater"]
            ]
            },
            {
              "title": ["Verdieping bereikbaar via vaste trap", "Etage erreichbar über eine feste Treppe", "Floor accessible via fixed stairs"],
              "elms": [
              ["Boxspring bedden", "Commode", "Opbergruimte"],
              ["Boxspring-Betten", "Kommode", "Stauraum"],
              ["Boxspring beds", "Chest of drawers", "Storage space"]
              ]
              },
              {
                "title": ["Patio", "Terrasse", "Patio"],
                "elms": [
                ["Buitenmeubels", "Parasol", "Eethoek buiten", "Hoofdingang met sleutel"],
                ["Gartenmöbel", "Sonnenschirm", "Essbereich im Freien", "Haupteingang mit Schlüssel"],
                ["Outdoor furniture", "Parasol", "Outdoor dining area", "Main entrance with key"]
                ]
                },
  ]);

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <div className="fac">
      {data.map((doc, index) => (
        <div className="fac_con" style={{ backgroundColor: `${index % 2 ? "#8ED3C2" : "#BDE8DA"}` }}>
          <div className="fac_con_tit">{doc.title[lang]}</div>
          <div className="fac_con_table">
            {doc.elms[lang].map((doci) => (
              <div className="fac_con_table_elm">
                <CheckIcon className="fac_icon" />
                {doci}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Faciliteiten;
