import React, { useEffect, useState } from "react";
import "../../assets/css/RecensieCreate.css";
import { db, auth } from "../../firebase";
import { useStateValue } from "../../Stateprovider";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
function RecensieCreate(props) {
  const [{ user, profile }, dispatch] = useStateValue();
  const history = useHistory();
  const [reviewText, setReviewText] = useState("");
  const [starArrNet, setStarArrNet] = useState([0, 0, 0, 0, 0]);
  const [starArrNauw, setStarArrNauw] = useState([0, 0, 0, 0, 0]);
  const [starArrCom, setStarArrCom] = useState([0, 0, 0, 0, 0]);
  const [starArrLoc, setStarArrLoc] = useState([0, 0, 0, 0, 0]);
  const [starArrAan, setStarArrAan] = useState([0, 0, 0, 0, 0]);
  const [starArrPrijs, setStarArrPrijs] = useState([0, 0, 0, 0, 0]);

  const [starsNet, setStarsNet] = useState(0);
  const [starsNauw, setStarsNauw] = useState(0);
  const [starsCom, setStarsCom] = useState(0);
  const [starsLoc, setStarsLoc] = useState(0);
  const [starsAan, setStarsAan] = useState(0);
  const [starsPrijs, setStarsPrijs] = useState(0);

  const [acces, setAcces] = useState(false);

  function addReview() {
    console.log(reviewText);
    if (user && acces) {
      db.collection("reviews")
        .add({
          text: reviewText,
          username: profile.username,
          userId: user.uid,
          createdAt: new Date().getTime().toString(),
          netheid: starsNet,
          nauwkeurigheid: starsNauw,
          communicatie: starsCom,
          locatie: starsLoc,
          aankomst: starsAan,
          prijsKwaliteit: starsPrijs,
        })
        .then(() => history.push("/reviews/show"));
    } else {
      console.log("no user found to add review");
    }
  }

  useEffect(() => {
    let isMounted = true; // note mutable flag

    if (profile?.email != undefined) {
      db.collection("orders")
        .where("email", "==", profile?.email)
        .get()
        .then((docs) => {
          docs.docs.map((doc, index) => {
            if (doc.data().reviewed) {
              if (isMounted) {
                setAcces(true);
              }
            } else {
              if (index === docs.length - 1) {
                history.push("/reviews/show");
              }
            }
          });
        })
        .catch((err) => console.log(err));
    } else {
      //   history.push("/reviews/show");
      console.log("no profile");
    }
    return () => {
      isMounted = false;
    };
  }, [profile]);

  function updateStarNet(index) {
    let newArr = [...starArrNet];
    for (let i = 0; i < index + 1; i++) {
      newArr[i] = 1;
    }
    for (let i = index + 1; i < 5; i++) {
      newArr[i] = 0;
    }
    setStarArrNet(newArr);
    setStarsNet(index + 1);
  }
  function updateStarNauw(index) {
    let newArr = [...starArrNauw];
    for (let i = 0; i < index + 1; i++) {
      newArr[i] = 1;
    }
    for (let i = index + 1; i < 5; i++) {
      newArr[i] = 0;
    }
    setStarArrNauw(newArr);
    setStarsNauw(index + 1);
  }
  function updateStarCom(index) {
    let newArr = [...starArrCom];
    for (let i = 0; i < index + 1; i++) {
      newArr[i] = 1;
    }
    for (let i = index + 1; i < 5; i++) {
      newArr[i] = 0;
    }
    setStarArrCom(newArr);
    setStarsCom(index + 1);
  }
  function updateStarLoc(index) {
    let newArr = [...starArrLoc];
    for (let i = 0; i < index + 1; i++) {
      newArr[i] = 1;
    }
    for (let i = index + 1; i < 5; i++) {
      newArr[i] = 0;
    }
    setStarArrLoc(newArr);
    setStarsLoc(index + 1);
  }
  function updateStarAan(index) {
    let newArr = [...starArrAan];
    for (let i = 0; i < index + 1; i++) {
      newArr[i] = 1;
    }
    for (let i = index + 1; i < 5; i++) {
      newArr[i] = 0;
    }
    setStarArrAan(newArr);
    setStarsAan(index + 1);
  }
  function updateStarPrijs(index) {
    let newArr = [...starArrPrijs];
    for (let i = 0; i < index + 1; i++) {
      newArr[i] = 1;
    }
    for (let i = index + 1; i < 5; i++) {
      newArr[i] = 0;
    }
    setStarArrPrijs(newArr);
    setStarsPrijs(index + 1);
  }
  return (
    <div className="recc">
      <div className="recc_header">Schrijf een recensie</div>

      <div className="recc_body">
        <div className="recc_body_element">
          <div className="recc_body_element_header">Vertel ons over je ervaring</div>
          <div className="recc_body_element_input">
            <textarea value={reviewText} onChange={(e) => setReviewText(e.target.value)} className="recc_text_input" name="Text1" cols="100" rows="7"></textarea>
          </div>
        </div>

        <div className="recc_body_element">
          <div className="recc_body_element_header">Geef een rating voor de netheid</div>
          <div className="recc_body_element_input">
            {starArrNet.map((doc, index) => (
              <div key={uuidv4()}>{doc === 1 ? <StarIcon onClick={() => updateStarNet(index)} /> : <StarBorderIcon onClick={() => updateStarNet(index)} />}</div>
            ))}
          </div>
        </div>

        <div className="recc_body_element">
          <div className="recc_body_element_header">Geef een rating voor de nauwkeurigheid</div>
          <div className="recc_body_element_input">
            {starArrNauw.map((doc, index) => (
              <div key={uuidv4()}>{doc === 1 ? <StarIcon onClick={() => updateStarNauw(index)} /> : <StarBorderIcon onClick={() => updateStarNauw(index)} />}</div>
            ))}
          </div>
        </div>

        <div className="recc_body_element">
          <div className="recc_body_element_header">Geef een rating voor de communicatie</div>
          <div className="recc_body_element_input">
            {starArrCom.map((doc, index) => (
              <div key={uuidv4()}>{doc === 1 ? <StarIcon onClick={() => updateStarCom(index)} /> : <StarBorderIcon onClick={() => updateStarCom(index)} />}</div>
            ))}
          </div>
        </div>

        <div className="recc_body_element">
          <div className="recc_body_element_header">Geef een rating voor de locatie</div>
          <div className="recc_body_element_input">
            {starArrLoc.map((doc, index) => (
              <div key={uuidv4()}>{doc === 1 ? <StarIcon onClick={() => updateStarLoc(index)} /> : <StarBorderIcon onClick={() => updateStarLoc(index)} />}</div>
            ))}
          </div>
        </div>

        <div className="recc_body_element">
          <div className="recc_body_element_header">Geef een rating voor de aankomst</div>
          <div className="recc_body_element_input">
            {starArrAan.map((doc, index) => (
              <div key={uuidv4()}>{doc === 1 ? <StarIcon onClick={() => updateStarAan(index)} /> : <StarBorderIcon onClick={() => updateStarAan(index)} />}</div>
            ))}
          </div>
        </div>

        <div className="recc_body_element">
          <div className="recc_body_element_header">Geef een rating voor de prijs-kwaliteit verhouding</div>
          <div className="recc_body_element_input">
            {starArrPrijs.map((doc, index) => (
              <div key={uuidv4()}>{doc === 1 ? <StarIcon onClick={() => updateStarPrijs(index)} /> : <StarBorderIcon onClick={() => updateStarPrijs(index)} />}</div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className='reccreate_text'>

               
                <textarea value={reviewText} onChange={(e)=>setReviewText(e.target.value)} className='reccreate_text_input' name="Text1" cols="40" rows="5"></textarea>

                <button onClick={()=>addReview()}>Post review</button>


           </div> */}
      <div className="recc_submit_contaier">
        <button className="recc_submit_container_btn" onClick={() => addReview()}>
          Post review
        </button>
      </div>
    </div>
  );
}

export default RecensieCreate;
