import React, { useEffect, useState } from "react";
import "../../assets/css/ReserverenRedirect.css";
import Calender from "./Calender";
import { useStateValue } from "../../Stateprovider";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveIcon from "@mui/icons-material/Remove";
import { useHistory } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";

function ReserverenRedirect(props) {
  const [{ bookDays, guests, curMinStay ,lang}, dispatch] = useStateValue();

  const [message,setMessage]=useState(["Klik hier om doorgestuurd te worden naar de reserveringspagina","Klicken Sie hier, um zur Reservierungsseite weitergeleitet zu werden","Click here to be redirected to the reservation page"])


  return (
    <div className="resr" >
        <div onClick={()=>window.open("https://www.walcherenvakanties.com/nl/vakantiehuizen-zeeland/di04?dateFrom=2023-08-11&dateTo=2023-08-14&accommodationPriceComponentGroup=643&numOfPersons=1")} className="resr1" >{message[lang]}</div>
        
        
     
    </div>
  );
}

export default ReserverenRedirect;