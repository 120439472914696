import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../../assets/css/Nav.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useStateValue } from "../../Stateprovider";
import { db, auth } from "../../firebase";
import MenuIcon from "@mui/icons-material/Menu";
import { v4 as uuidv4 } from "uuid";
// import { visuallyHidden } from "@mui/utils";
import { NL } from "country-flag-icons/react/3x2";
import { DE } from "country-flag-icons/react/3x2";
import { US } from "country-flag-icons/react/3x2";
import { GB } from "country-flag-icons/react/3x2";
import { ConstructionOutlined } from "@mui/icons-material";
function Nav({ home }) {
  const [{ user, profile, lang }, dispatch] = useStateValue();
  const [profileMenuIsOpen, setProfileMenuIsOpen] = useState(false);
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [showMenu2, setShowMenu2] = useState(false);
  const [showMenu3, setShowMenu3] = useState(false);

  const [userItems,setUserItems]=useState([
    {
      link: "account",
      text: ["account", "account", "account"],
    },
    {
      link: "mybookings",
      text: ["mijn boekingen", "my bookings", "my bookings"],
    }

  ])

  const [navItems, setNavItems] = useState([
    {
      link: "hethuisje",
      text: ["Uw verblijf", "Dein Aufenthalt", "Your stay"],
    },
    {
      link: "faciliteiten",
      text: ["Faciliteiten", "Einrichtungen", "Facilities"],
    },
    {
      link: "liggingenomgeving",
      text: [
        "Ligging en omgeving",
        "Lage und Umgebung",
        "Location and surroundings",
      ],
    },
    // { link: "reviews/show", text: ["Reviews", "Bewertungen", "Reviews"] },
    {
      link: "contact",
      text: ["Service & Contact", "Service & Kontakt", "Service & Contact"],
    },
    { link: "reserveren", text: ["Reserveren", "Buchen", "Book"] },
  ]);

  const [posLangs, setPosLangs] = useState([
    { flag: <DE title="German" className="navbar_flags_item_com" />, index: 1 },
    {
      flag: <GB title="English" className="navbar_flags_item_com" />,
      index: 2,
    },
  ]);

  function changeLan(index) {
    dispatch({
      type: "SET_LANG",
      lang: index,
    });
    if (index === 0) {
      setPosLangs([
        {
          flag: <DE title="German" className="navbar_flags_item_com" />,
          index: 1,
        },
        {
          flag: <GB title="English" className="navbar_flags_item_com" />,
          index: 2,
        },
      ]);
    }
    if (index === 1) {
      setPosLangs([
        {
          flag: <NL title="Dutch" className="navbar_flags_item_com" />,
          index: 0,
        },
        {
          flag: <GB title="English" className="navbar_flags_item_com" />,
          index: 2,
        },
      ]);
    }
    if (index === 2) {
      setPosLangs([
        {
          flag: <NL title="Dutch" className="navbar_flags_item_com" />,
          index: 0,
        },
        {
          flag: <DE title="German" className="navbar_flags_item_com" />,
          index: 1,
        },
      ]);
    }
  }

  const handleAuth = () => {
    if (user) {
      auth.signOut();
      dispatch({
        type: "SET_USER",
        user: null,
      });
      history.push("/login");
    }
  };

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      window.addEventListener("click", checkClick);
      return () => {
        window.removeEventListener("click", checkClick);
      };
    }
    return () => (isSubscribed = false);
  }, []);
  useEffect(() => {
    checkSize()
    window.addEventListener("resize", checkSize);
    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, []);
  const checkClick = (e) => {
    //  console.log(e.target.closest('#drop1'))

    setProfileMenuIsOpen(false);
    setShowMenu2(false);
    setShowMenu3(false)
  };
  const checkSize = (e) => {
    //  console.log(e.target.closest('#drop1'))
   
    if (window.innerWidth <= 768) {
      setShowMenu(true);
      
    } else {
      setShowMenu(false);
    }
  };

  return (
    <div className={`navbar ${home&&"navbar_trans"}`}>
      {/* <div className="navbar_bg"></div> */}
      <div className="navbar_brand">
        <Link to="">
          <img
            src="/images/logo.png"
            alt="logo"
            className="navbar_item_img "
          />
        </Link>
      </div>

      {showMenu && (
        <div className="navbar_menu_small">
        <MenuIcon
          onClick={(e) => {
            e.stopPropagation();
            setShowMenu2(!showMenu2);
          }}
          className="navbar_small"
          fontSize="large"
          color="black"
        />
        </div>
      )}

      <div className="navbar_menu">
        {navItems.map((doc) => (
          <div key={uuidv4()} className="navbar_menu_item">
            <Link to={`/${doc.link}`}>
              <button className="dropbtn navHover">
                {doc.text[lang]}
              </button>
            </Link>
          </div>
        ))}
      </div>

      {showMenu2 && (
        <div className="navbar_menu2">
          <div className="dropdown">
              <div
               
              >
                <button className="dropbtn2_menu ">
                  Menu
                </button>
              </div>
            </div>
          {navItems.map((doc) => (
            <div className="dropdown">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/${doc.link}`);
                  setShowMenu2(false);
                }}
              >
                <button className="dropbtn2  navHover">
                  {doc.text[lang]}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="navbar_flags">
        <div
          className="navbar_flags_item"
          onClick={() => changeLan(posLangs[0].index)}
        >
          {posLangs[0].flag}
        </div>
        <div
          className="navbar_flags_item"
          onClick={() => changeLan(posLangs[1].index)}
        >
          {posLangs[1].flag}
        </div>
      </div>

      {/* <div className="navbar_user">
        <div className="dropdown">
          <button onClick={(e) => {
            e.stopPropagation();
            setShowMenu3(!showMenu3);
          }} className="dropbtn  px-4 text-decoration-none navHover">
            
            Hello, {profile?.username}
          </button>
         
        </div>
      </div> */}

      {showMenu3 && (
        <div className="navbar_menu3">
          <div className="dropdown">
          {userItems.map((doc) => (
            
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/${doc.link}`);
                  setShowMenu3(false);
                }}
              >
                <button className="dropbtn2 navbar-item px-4 text-decoration-none navHover">
                  {doc.text[lang]}
                </button>
              </div>
              
                
              
           
          ))}
         <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleAuth()
                }}
              >
                <button className="dropbtn2 navbar-item px-4 text-decoration-none navHover">
                 logout
                </button>
              </div>
                </div>
        </div>
      )}
    </div>
  );
}

export default Nav;
