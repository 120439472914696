export const initialState = {
  user: null,
  profile: [],
  handle: null,
  bookDays: [],
  guestInfo: {},
  curMinStay: 7,
  priceDetails: {},
  lang:0
};

const reducer = (state, action) => {
  // console.log(action)
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
      case "SET_LANG":
        return {
          ...state,
          lang: action.lang,
        };
    case "SET_PRICE_DETAILS":
      return {
        ...state,
        priceDetails: action.priceDetails,
      };
    case "SET_MIN_STAY":
      return {
        ...state,
        curMinStay: action.curMinStay,
      };

    case "SET_HANDLE":
      return {
        ...state,
        handle: action.handle,
      };

    case "SET_GUEST_INFO":
      return {
        ...state,
        guestInfo: action.guestInfo,
      };

    case "SET_BOOK_DAYS":
      return {
        ...state,
        bookDays: action.bookDays,
      };

    case "SET_PROFILEPOP":
      return {
        ...state,
        profilepop: action.profilepop,
      };

    case "SET_PROFILE":
      return {
        ...state,
        profile: action.profile,
      };

    default:
      return state;
  }
};
export default reducer;
