import React, { useState,useEffect } from "react";
import "../../assets/css/Contact.css";
import AccessibleIcon from "@mui/icons-material/Accessible";
import { Link, useHistory } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { v4 as uuidv4 } from "uuid";
import PhoneIcon from "@mui/icons-material/Phone";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PetsIcon from "@mui/icons-material/Pets";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EditIcon from "@mui/icons-material/Edit";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import KeyIcon from "@mui/icons-material/Key";
import { useStateValue } from "../../Stateprovider";

function Contact(props) {
  const [{ lang }, dispatch] = useStateValue();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  const [dataIndex, setDataIndex] = useState(0);
  const [test, setTest] = useState(<ArrowBackIosNewIcon />);
  const [data, setData] = useState([
    {
      showDesc: true,
      index: 0,
      title: ["Parkeren", "Parken", "Parking"],
      desc: [
        "U kunt uw auto gratis parkeren op ons eigen terrein op een door ons aangewezen plek. In Dishoek moet u of uw bezoek, betalen voor het parkeren buiten het terrein van het verblijf. U kunt de tarieven vinden op de website van de gemeente Veere",
        "Sie können Ihr Auto kostenlos auf unserem eigenen Gelände an einem von uns zugewiesenen Ort parken. In Dishoek müssen Sie oder Ihre Gäste für das Parken außerhalb des Geländes der Unterkunft bezahlen. Die Tarife finden Sie auf der Website der Gemeinde Veere.",
        "You can park your car for free in our own grounds at a location designated by us. In Dishoek you or your guests must pay for parking outside the accommodation grounds. You can find the rates on the website of the municipality of Veere.",
      ],
    },

    {
      showDesc: true,
      index: 1,
      title: ["Contact", "Kontakt", "Contact"],
      desc: false,
      descElms: [[
        "Dishoek bij zee",
        "Westerscheldelaan 17, 4371PP Dishoek",
        "+31 6 27382501",
        "info@dishoekbijzee.nl"
      ],[
        "Dishoek bij zee",
        "Westerscheldelaan 17, 4371PP Dishoek",
        "+31 6 27382501",
        "info@dishoekbijzee.nl"
      ],[
        "Dishoek bij zee",
        "Westerscheldelaan 17, 4371PP Dishoek",
        "+31 6 27382501",
        "info@dishoekbijzee.nl"
      ]],
    },

    {
      showDesc: true,
      index: 2,
      title: ["Honden", "Hunde", "Dogs"],
      desc: [
        "Er zijn maximaal 2 honden welkom. Bij uw boeking kunt u aangeven hoeveel honden u mee wilt nemen.",
        "Es sind maximal 2 Hunde willkommen. Bei Ihrer Buchung können Sie angeben, wie viele Hunde Sie mitbringen möchten.",
        "Up to 2 dogs are welcome. When booking, you can indicate how many dogs you want to bring.",
      ],
    },

    { showDesc: false, index: 3, title: ["Wat zijn de aankomst tijden?", "Was sind die Ankunftszeiten?", "What are the arrival times?"], desc:  [
      "U kunt inchecken tussen 15:00 en 22:00 uur. Als u onverhoopt aankomt na 22:00 uur, laat dit ons dan zo snel mogelijk weten via whatsapp of per mail. De contactgegevens kunt u vinden op de website onder 'service en contact'.",
      "Sie können zwischen 15:00 und 22:00 Uhr einchecken. Wenn Sie unerwartet nach 22:00 Uhr ankommen, informieren Sie uns bitte so schnell wie möglich per WhatsApp oder E-Mail. Die Kontaktinformationen finden Sie auf der Website unter 'Service und Kontakt'.",
      "You can check in between 3:00 pm and 10:00 pm. If you unexpectedly arrive after 10:00 pm, please let us know as soon as possible via WhatsApp or email. You can find the contact details on the website under 'Service and Contact'."
    ] },
    {
      showDesc: false,
      index: 3,
      title: ["Wat zijn de vertrektijden?", "Was sind die Abfahrtszeiten?", "What are the check-out times?"],
      desc: ["Op de vertrekdag vragen we u om het verblijf weer voor 10:00 uur vrij te maken", "Am Abreisetag bitten wir Sie, das Zimmer bis 10:00 Uhr zu verlassen.", "On the day of departure, we kindly ask you to vacate your room by 10:00 am."]
    },
    {
      showDesc: false,
      index: 3,
      title: [
        "Wat moet ik laten zien als ik aankom ?",
        "Was muss ich bei meiner Ankunft vorzeigen?",
        "What do I need to show when I arrive?"
      ],
      desc: [
        "U ontvangt de reserveringsbevestiging inclusief factuur binnen 24 uur na het vastleggen van uw reservering per e-mail. Bewaar deze goed! U kunt de reserveringsbevestiging laten zien op uw mobiel. Zorg er ook voor dat eventuele openstaande bedragen zijn betaald. Bij aankomst kan er om een legitimatiebewijs gevraagd worden.",
        "Sie erhalten innerhalb von 24 Stunden nach Ihrer Reservierung per E-Mail eine Reservierungsbestätigung einschließlich Rechnung. Bewahren Sie diese gut auf! Sie können die Reservierungsbestätigung auf Ihrem Mobiltelefon vorzeigen. Stellen Sie auch sicher, dass offene Beträge bezahlt sind. Bei der Ankunft kann ein Identitätsnachweis verlangt werden.",
        "You will receive the reservation confirmation including invoice by email within 24 hours after booking your reservation. Keep this safe! You can show the reservation confirmation on your mobile. Also, make sure any outstanding amounts have been paid. An identification document may be requested upon arrival."
      ]
    },
    
    {
      showDesc: false,
      index: 3,
      title: ["Kan ik tegen betaling ook eerder inchecken of aankomen?", "Kann ich gegen Bezahlung früher einchecken oder ankommen?", "Can I check-in or arrive earlier for an additional fee?"],
      desc: ["In verband met het schoonmaken van ons verblijf kunt u niet eerder inchecken dan vanaf 15.00 uur.", "Aufgrund der Reinigung unserer Unterkunft können Sie erst ab 15:00 Uhr einchecken.", "Due to cleaning purposes, you cannot check-in earlier than 3 pm."]
    },
    
    {
      showDesc: false,
      index: 3,
      title: ["Kan ik ook toegang krijgen tot het verblijf, als ik niet de hoofdboeker ben?", "Kann ich auch Zugang zur Unterkunft erhalten, wenn ich nicht der Hauptbucher bin?", "Can I also get access to the accommodation, if I am not the main booker?"],
      desc: ["Ja, wanneer u de reserveringsbevestiging kunt laten zien, mag u als reisgenoot toegang krijgen.", "Ja, wenn Sie die Buchungsbestätigung vorzeigen können, dürfen Sie als Mitreisender Zugang erhalten.", "Yes, if you can show the booking confirmation, you may get access as a travel companion."]
    },
    

    // {
    //   showDesc: false,
    //   index: 4,
    //   title: ["Kan ik de periode van mijn geboekte verblijf wijzigen?" , "Kann ich den Zeitraum meines gebuchten Aufenthalts ändern?", "Can I change the period of my booked stay?"],
    //   desc: ["Indien de huurder de periode van het geboekte verblijf wil wijzigen dan is dat mogelijk als de aankomstdatum van het verblijf niet binnen de 28 dagen vóór aankomstdatum valt. Neem hiervoor contact met ons op. We helpen u graag verder. De contactgegevens kunt u vinden op de website onder 'praktische informatie'. Houd er rekening mee dat de huurprijs gelijk blijft, maar ook hoger kan worden. Als de gewijzigde boeking duurder wordt, dan betaalt u het verschil extra. Valt u aankomsdatum binnen 28 dagen? Dan is het helaas niet meer mogelijk om de reisdata te wijzigen.",
    //          "Wenn der Mieter den Zeitraum seines gebuchten Aufenthalts ändern möchte, ist dies möglich, wenn das Ankunftsdatum des Aufenthalts nicht innerhalb von 28 Tagen vor dem Ankunftsdatum liegt. Nehmen Sie dazu Kontakt mit uns auf. Wir helfen Ihnen gerne weiter. Die Kontaktdaten finden Sie auf der Website unter 'praktische Informationen'. Bitte beachten Sie, dass der Mietpreis gleich bleiben kann, aber auch höher ausfallen kann. Wenn die geänderte Buchung teurer wird, müssen Sie den Aufpreis extra bezahlen. Fällt Ihr Ankunftsdatum innerhalb von 28 Tagen? Dann ist es leider nicht mehr möglich, die Reisedaten zu ändern.",
    //          "If the renter wants to change the period of the booked stay, it is possible if the arrival date of the stay does not fall within 28 days prior to the arrival date. Please contact us for this. We are happy to assist you further. You can find the contact details on the website under 'practical information'. Please note that the rental price may remain the same, but can also be higher. If the modified booking becomes more expensive, you will have to pay the difference. If your arrival date falls within 28 days, it is unfortunately no longer possible to change the travel dates."]
    // },
    
    // {
    //   showDesc: false,
    //   index: 4,
    //   title: ["Hoe kan ik mijn persoonlijke gegevens aanpassen?", "Wie kann ich meine persönlichen Daten ändern?", "How can I update my personal information?"],
    //   desc: ["Als uw persoonlijke gegevens niet juist zijn in uw reservering, neem dan contact met ons op. We helpen u graag verder en zorgen ervoor dat uw gegevens worden bijgewerkt. U kunt onze contactgegevens vinden op de website onder 'service en contact'.",
    //   "Wenn Ihre persönlichen Daten in Ihrer Reservierung nicht korrekt sind, setzen Sie sich bitte mit uns in Verbindung. Wir helfen Ihnen gerne weiter und stellen sicher, dass Ihre Daten aktualisiert werden. Sie können unsere Kontaktdaten auf der Website unter 'Service und Kontakt' finden.",
    //   "If your personal details are incorrect in your reservation, please contact us. We are happy to assist you further and ensure that your details are updated. You can find our contact details on the website under 'service and contact'."]
    //   },
    //   {
    //     showDesc: false,
    //     index: 4,
    //     title: ["Hoe voeg ik een reisgenoot toe of verwijder ik deze?", "Wie füge ich einen Reisebegleiter hinzu oder entferne ihn?", "How do I add or remove a travel companion?"],
    //     desc: ["Neem contact op met ons op. We helpen je daar graag mee verder. De contactgegevens kunt u vinden op de website onder 'service en contact'.", "Nehmen Sie Kontakt mit uns auf. Wir helfen Ihnen gerne weiter. Die Kontaktdaten finden Sie auf der Website unter 'Service und Kontakt'.", "Please contact us. We are happy to assist you further. You can find the contact details on the website under 'service and contact'."]
    //     },
    //     {
    //       showDesc: false,
    //       index: 4,
    //       title: ["Ik wil graag bezoek ontvangen tijdens mijn verblijf. Hoe werkt dit?", "Wie funktioniert es, wenn ich Besuch während meines Aufenthalts empfangen möchte?", "I would like to receive visitors during my stay. How does this work?"],
    //       desc: ["Als uw visite niet blijft slapen, dan hoeft u niets aan ons door te geven. Blijven ze wel slapen? Dat is alleen mogelijk als dan het totaal aantal mensen dat in het huisje blijft slapen niet meer is dan 4. U betaalt dan ook toeristenbelasting voor de gast(en). (Lucht)bedden meenemen is niet toegestaan.",
    //       "Wenn Ihr Besuch nicht übernachtet, müssen Sie uns nichts mitteilen. Übernachten sie jedoch? Das ist nur möglich, wenn die Gesamtzahl der Personen, die im Ferienhaus übernachten, nicht mehr als 4 beträgt. Sie zahlen dann auch Kurtaxe für den/die Gäste. Das Mitnehmen von (Luft)betten ist nicht erlaubt.",
    //       "If your visitors are not staying overnight, you do not need to inform us. If they are staying overnight, it is only possible if the total number of people staying in the cottage is no more than 4. You also have to pay tourist tax for the guest(s). Bringing (air) beds is not allowed."]
    //       },
    //       {
    //         showDesc: false,
    //         index: 4,
    //         title: ["Ik wil graag mijn reservering annuleren. Hoe doe ik dat?", "Ich möchte meine Reservierung stornieren. Wie mache ich das?", "I want to cancel my reservation. How do I do that?"],
    //         desc: false,
    //         descElms: [
    //         ["Voor annuleringen kunnen wij, afhankelijk van de aankomstdatum en het moment van reserveren, kosten in rekening brengen. Bij annulering is huurder de volgende annuleringskosten verschuldigd:", "a. Bij annulering(en) tot 30 dagen voor aankomst, bent u 30% van het totale huurbedrag (de aanbetaling) verschuldigd.", "b. Bij annulering(en) tussen de 30 en 8 dagen voor aankomst bent u 75% van het totale huurbedrag verschuldigd.", "c. Bij annulering(en) minder dan 7 dagen voor aankomst bent u 100% van het totale huurbedrag verschuldigd."],
    //         ["Für Stornierungen können je nach Ankunftsdatum und Reservierungszeitpunkt Kosten anfallen. Bei Stornierung sind die folgenden Stornokosten fällig:", "a. Bei Stornierung(en) bis zu 30 Tagen vor Ankunft sind Sie 30% des Gesamtmietbetrags (Anzahlung) schuldig.", "b. Bei Stornierung(en) zwischen 30 und 8 Tagen vor Ankunft sind Sie 75% des Gesamtmietbetrags schuldig.", "c. Bei Stornierung(en) weniger als 7 Tage vor Ankunft sind Sie 100% des Gesamtmietbetrags schuldig."],
    //         ["Cancellation fees may apply depending on the arrival date and the time of booking. In case of cancellation, the tenant will be liable for the following cancellation fees:", "a. In case of cancellation(s) up to 30 days before arrival, you will be liable for 30% of the total rental amount (the down payment).", "b. In case of cancellation(s) between 30 and 8 days before arrival, you will be liable for 75% of the total rental amount.", "c. In case of cancellation(s) less than 7 days before arrival, you will be liable for 100% of the total rental amount."]
    //         ]
    //         },
    // {
    //   showDesc: false,
    //   index: 4,
    //   title: ["Binnen hoeveel weken ontvang ik mijn geld terug na het annuleren van mijn reservering?",
    //   "Wann erhalte ich nach der Stornierung meiner Reservierung mein Geld zurück?",
    //   "Within how many weeks will I receive my money back after cancelling my reservation?"],
    //   desc: ["Indien u recht heeft op een geheel of gedeeltelijke terugbetaling van het huurbedrag, dan ontvangt u binnen 4 weken het bedrag retour op de rekening waarmee de betaling werd voltooid.",
    //   "Wenn Sie Anspruch auf eine vollständige oder teilweise Rückerstattung des Mietbetrags haben, erhalten Sie den Betrag innerhalb von 4 Wochen auf das Konto zurück, mit dem die Zahlung erfolgt ist.",
    //   "If you are entitled to a full or partial refund of the rental amount, you will receive the amount back within 4 weeks on the account with which the payment was completed."]
    //   },
    //   {
    //     showDesc: false,
    //     index: 4,
    //     title: [
    //       "Kan ik een annuleringsverzekering afsluiten?",
    //       "Kan ik een annuleringsverzekering afsluiten?",
    //       "Can I take out cancellation insurance?"
    //     ],
    //     desc: [
    //       "Bij ons kunt u geen annuleringsverzekering afsluiten.",
    //       "Bei uns können Sie keine Stornoversicherung abschließen.",
    //       "You cannot take out cancellation insurance with us."
    //     ]
    //   },

    {
      showDesc: true,
      index: 4,
      title: ["Minder valide badgasten",
      "behinderte Badegäste",
              "disabled bathers",
    ],
      desc: ["In dishoek kunnen minder valide badgasten gebruik maken van een gator, die de gast over de duinen rijdt en de bezoeker op plaats van bestemming brengt. Een gator is een klein en open dieselvoertuig dat over de duinen en strand kan rijden. Aanvragen verlopen via de strandwachtpost (tel: +31 118-551341). Afspreken kan van 10:00 A.M. tot 18:00 P.M.",
            "In Dishoek können behinderte Badegäste einen Alligator nutzen, der den Gast über die Dünen treibt und ihn an sein Ziel bringt. Ein Gator ist ein kleines und offenes Dieselfahrzeug, das über die Dünen und den Strand fahren kann. Anfragen werden über die Rettungsschwimmerstation gestellt (Tel.: +31 118-551341). Ihr könnt euch ab 10:00 Uhr treffen. bis 18:00 Uhr",
            "In dishoek, disabled bathers can use a gator, which drives the guest over the dunes and takes the visitor to their destination. A gator is a small and open diesel vehicle that can drive over the dunes and beach. Requests are made via the lifeguard station (tel: +31 118-551341). You can meet from 10:00 A.M. until 6:00 PM"]
    },
      
      {
        showDesc: false,
        index: 5,
        title: ["Ik ben mijn reserveringsbevestiging kwijt. Wat moet ik doen?",
                "Ich habe meine Reservierungsbestätigung verloren. Was soll ich tun?",
                "I lost my reservation confirmation. What should I do?"],
        desc: ["Binnen een uur na het maken van uw reservering ontvangt u van ons een bevestiging per e-mail. Heeft u deze niet ontvangen of bent u deze kwijt? Check voor de zekerheid of deze niet tussen uw ongewenste e-mails staat. Is dit niet het geval? Neem dan contact met ons op. De contactgegevens kunt u vinden op de website onder 'service en contact'. We mailen de reserveringsbevestiging dan opnieuw.",
              "Sie erhalten innerhalb einer Stunde nach Ihrer Buchung eine Bestätigung per E-Mail von uns. Haben Sie diese nicht erhalten oder verloren? Überprüfen Sie zur Sicherheit, ob diese nicht in Ihrem Spam-Ordner gelandet ist. Ist dies nicht der Fall? Bitte kontaktieren Sie uns. Die Kontaktdaten finden Sie auf der Website unter 'service en contact'. Wir senden Ihnen dann die Reservierungsbestätigung erneut per E-Mail zu.",
              "You will receive a confirmation by email from us within an hour of making your reservation. Haven't received it or lost it? Check your spam folder to make sure it didn't end up there. If not, please contact us. You can find the contact details on the website under 'service and contact'. We will then resend the reservation confirmation to you by email."]
      },
      
      {
        showDesc: false,
        index: 5,
        title: ["Ik wil graag langer dan 2 weken verblijven in het huisje. Hoe kan ik boeken?", "Ich möchte gerne länger als 2 Wochen im Ferienhaus bleiben. Wie kann ich buchen?", "I would like to stay in the cottage for more than 2 weeks. How can I book?"],
        desc: ["De maximale verblijfsduur is 2 weken aansluitend. Voor een verblijf vanaf 2 weken kunt u contact met ons opnemen. De contactgegevens kunt u vinden op de website onder 'service en contact'.",
               "Die maximale Aufenthaltsdauer beträgt 2 Wochen in Folge. Für einen Aufenthalt ab 2 Wochen können Sie Kontakt mit uns aufnehmen. Die Kontaktdaten finden Sie auf der Website unter 'Service und Kontakt'.",
               "The maximum length of stay is 2 weeks in a row. For a stay of 2 weeks or more, please contact us. You can find the contact details on the website under 'service and contact'."]
      },
      
      {
        showDesc: false,
        index: 5,
        title: ["Is het mogelijk om een optie te nemen op een reservering?", "Ist es möglich, eine Option für eine Reservierung zu nehmen?", "Is it possible to place an option on a reservation?"],
        desc: ["Nee. dat is helaas niet mogelijk. ", "Nein, das ist leider nicht möglich. ", "No, unfortunately that is not possible."]
      },
      
      {
        showDesc: false,
        index: 5,
        title: ["Kan ik werknemers onderbrengen in het verblijf?", "Kann ich Mitarbeiter in der Unterkunft unterbringen?", "Can I accommodate employees in the accommodation?"],
        desc: ["Nee, dat is niet mogelijk. De overeenkomst betreft de verhuur van de vakantiewoning voor louter en alleen recreatief gebruik.", "Nein, das ist nicht möglich. Die Vereinbarung bezieht sich ausschließlich auf die Vermietung der Ferienwohnung für Erholungszwecke.", "No, that's not possible. The agreement concerns the rental of the holiday home for recreational use only."]
      },
      
    { showDesc: false, index: 5, title: ["Wat gebeurt er na mijn reservering?", "Was passiert nach meiner Reservierung?", "What happens after my reservation?"], desc: false, descElms: [["1. Na uw reservering ontvangt u een reserveringsbevesting in de mail", "2. U ontvangt een mail met een verzoek om de huurovereenkomst digitaal te ondertekenen."],["1. Nach Ihrer Reservierung erhalten Sie eine Reservierungsbestätigung per E-Mail", "2. Sie erhalten eine E-Mail mit der Aufforderung, den Mietvertrag digital zu unterzeichnen."],["1. After your reservation, you will receive a reservation confirmation by email", "2. You will receive an email requesting you to digitally sign the rental agreement."]] },

    {
      showDesc: false,
      index: 6,
      title: [
      "Politie (niet spoedeisend): 0900-8844",
      "Polizei (nicht dringend): 0900-8844",
      "Police (non-urgent): 0900-8844"
      ],
      desc:[
        "Politie (niet spoedeisend): 0900-8844",
        "Polizei (nicht dringend): 0900-8844",
        "Police (non-urgent): 0900-8844"
        ],
      },
    { showDesc: false, index: 6, title:  ["Brandweer, ambulance, politie: 112", "Brandweer, ambulance, Polizei: 112", "Fire department, ambulance, police: 112"],desc:["Brandweer, ambulance, politie: 112", "Brandweer, ambulance, Polizei: 112", "Fire department, ambulance, police: 112"] },
    { showDesc: false, index: 6, title: ["Tandarts: 0111-12343456","Zahnarzt: 0111-12343456","Dentist: 0111-12343456"], desc: ["Tandarts: 0111-12343456","Zahnarzt: 0111-12343456","Dentist: 0111-12343456"] },
    { showDesc: false, index: 6, title: ["Huisartsenpost Zeeland: 0900-1985","Hausarztpraxis Zeeland: 0900-1985","General practitioner's office Zeeland: 0900-1985"], desc: false, descElms: [["Avond- en nachtdiensten: 17.00 uur tot de volgende ochtend 08.00 uur", "Krooneveldweg 6", "4332 BL Middelburg", "Locatie: U vindt zowel de huisartsenpost als de dienstapotheek bij de rotonde Veerse Poort, bij de molen.", "Navigatie in Google Maps: het Walchers Zorgknooppunt", "Afspraak: U dient eerst een afspraak te maken met de huisartsenpost alvorens u langs kunt komen: telefoonnummer 0900-1985."],
    ["Abend- und Nachtdienste: 17.00 Uhr bis zum nächsten Morgen um 08.00 Uhr.", "Krooneveldweg 6", "4332 BL Middelburg", "Standort: Sie finden sowohl den ärztlichen Bereitschaftsdienst als auch die Notdienstapotheke am Kreisverkehr Veerse Poort, in der Nähe der Mühle.", "Navigation in Google Maps: het Walchers Zorgknooppunt", "Terminvereinbarung: Sie müssen zunächst einen Termin mit dem ärztlichen Bereitschaftsdienst vereinbaren, bevor Sie kommen können: Telefonnummer 0900-1985."],
    ["Evening and night shifts: from 5:00 pm until the next morning at 8:00 am.", "Krooneveldweg 6", "4332 BL Middelburg", "Location: You can find both the general practitioner's emergency service and the emergency pharmacy at the Veerse Poort roundabout, near the mill.", "Navigation in Google Maps: het Walchers Zorgknooppunt", "Appointment: You must first make an appointment with the general practitioner's emergency service before you can come: telephone number 0900-1985."]] },
    
    { showDesc: false, index: 6, title: ["Dierenarts: 0118 466 548","Tierarzt: 0118 466 548","Veterinarian: 0118 466 548"], desc: false, descElms: [["Dierenkliniek De Ark Vlissingen", "Alexander Gogelweg 14, 4384 EV Vlissingen"],
    ["Dierenkliniek De Ark Vlissingen", "Alexander Gogelweg 14, 4384 EV Vlissingen"],
    ["Dierenkliniek De Ark Vlissingen", "Alexander Gogelweg 14, 4384 EV Vlissingen"]] },
    { showDesc: false, index: 6, title: ["+31 6 27382501","+31 6 27382501","+31 6 27382501"], desc: ["+31 6 27382501","+31 6 27382501","+31 6 27382501"] },

    {
      showDesc: true,
      index: 7,
      title: ["Hoe werkt de digitale sleutel", "Wie funktioniert der digitale Schlüssel?", "How does the digital key work?"],
      desc: ["U krijgt na het ontvangen van uw reservering de pincode van de deur in de mail gestuurd", "Nach Erhalt Ihrer Reservierung erhalten Sie den Tür-PIN-Code per E-Mail.", "After receiving your reservation, you will receive the door PIN code in the mail."]
      }
  ]);

  const [titles, setTitles] = useState([
    {
      title: ["Parkeren", "Parken", "Parking"],
      icon: <LocalParkingIcon fontSize="large" color="success" />
    },
    {
      title: ["Contact", "Kontakt", "Contact"],
      icon: <WhatsAppIcon fontSize="large" color="success" />
      },
      {
        title: ["Honden", "Hunde", "Dogs"],
        icon: <PetsIcon fontSize="large" color="success" />,
        },
        {
          title: ["Aankomst en vertrek", "Ankunft und Abfahrt", "Arrival and departure"],
          icon: <CalendarTodayIcon fontSize="large" color="success" />
          },
          // { title: ["Wijzigen & annuleren", "Bearbeiten & Stornieren", "Change & Cancel"], icon: <EditIcon fontSize="large" color="success" /> },
          // {
          //   title: ["Reserveren & betalen", "Reservieren & Bezahlen", "Booking & Payment"],
          //   icon: <CreditCardIcon fontSize="large" color="success" />
          //   },
            // {
            //   title: ["Belangrijke telefoonnummers", "Wichtige Telefonnummern", "Important phone numbers"],
            //   icon: <PhoneIcon fontSize="large" color="success" />
            //   },
              // { title: ["Digitale sleutel", "Digitale Schlüssel", "Digital key"], icon: <KeyIcon fontSize="large" color="success" /> },
               {
              title: ["Strand", "Strand", "Beach"],
              icon: <PhoneIcon fontSize="large" color="success" />
              },
  ]);

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  function updateShowDesc(index) {
    let newArr = [...data];
    data[index].showDesc = !data[index].showDesc;
    setData(newArr);
  }
  return (
    <div className="contact" style={{ background: `linear-gradient(to left, #D6E8C6 60%, white 40%) ` }}>
      <div className="contact_title">Service en contact</div>
      {!show ? (
        <div className="contact_grid_container">
          {titles.map((doc, index) => (
            <div
              key={uuidv4()}
              onClick={() => {
                setShow(true);
                setDataIndex(index);
              }}
              className="contact_block"
            >
              <div className="contact_block_icon" >{doc.icon}</div>

              <div className="contact_block_title">{doc.title[lang]}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="contact_faq">
          <div style={{ display: "flex" }}>
            <ArrowBackIosNewIcon className="app_icon" onClick={() => setShow(false)} fontSize="large" />
          </div>
          {data.map((doc, index) => (
            <div key={uuidv4()}>
              {doc.index === dataIndex && (
                <div onClick={() => updateShowDesc(index)} className="contact_faq_elm">
                  <div className="contact_faq_title">
                    <p style={{ display: "flex" }}>{data[index].title[lang]}</p>
                    <ExpandMoreIcon className="contact_icon" fontSize="large" />
                  </div>
                  {doc.showDesc && doc.desc != false && <div className="contact_faq_elm_desc">{data[index].desc[lang]}</div>}
                  {doc.showDesc && doc.desc === false && (
                    <div className="contact_faq_elm_desc">
                      {data[index].descElms[lang].map((doc) => (
                        <div className="contact_faq_elm_desc_item">{doc}</div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Contact;
