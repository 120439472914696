import React, {useLayoutEffect, useEffect, useState } from "react";
import "../assets/css/Home.css";
import data from "./priceData.json";
import { db } from "../firebase";
// import Mail from "./mail/Mail";
import ReactDOMServer from "react-dom/server";
import { TextareaAutosize } from "@mui/material";
import Mail1 from "./mail/Mail1";
import Mail2 from "./mail/Mail2";
import Mail3 from "./mail/Mail3";
import Mail4 from "./mail/Mail4";
import Mail5 from "./mail/Mail5";
import { mailtest } from "./mail1";


function Home() {
  const [seconds, setSeconds] = useState(0);
  const [homeFotos,setHomeFotos]=useState(["/images/home7.jpg","/images/home6.jpg"
  // ,"/images/homedishoek3.jpg"
  // ,"/images/homedishoek4.jpg"


  ])
  const [homeFotosIndex,setHomeFotosIndex]=useState(0)
  const [fotos, setFotos] = useState(["/images/moun1.jpg", "/images/moun2.jpg", "/images/moun3.jpg", "/images/living1.jpg"]);
  const [curFoto, setCurFoto] = useState({ url: fotos[0], index: 0 });
  const [months, setMonths] = useState(["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"]);
  const [count, setCount] = useState(0);
  const [height1,setHeight1]=useState(0)
  useEffect(() => {
    
    window.addEventListener("resize", checkHeight);
    return () => {
      window.removeEventListener("resize", checkHeight);
    };
  }, []);

  useEffect(() => {
    let interval = null;
    
      interval = setInterval(() => {
        setSeconds(seconds + 1);
      }, 1000);

      if(seconds>=5){
        if(homeFotosIndex===homeFotos.length-1){
          setHomeFotosIndex(0)
        }else{
          setHomeFotosIndex(homeFotosIndex+1)
        }
        setSeconds(0)
      }
    
    return () => clearInterval(interval);
  }, [seconds]);

  function checkHeight(){
    var myImg = document.querySelector("#backImg");
   
    var currHeight = myImg.clientHeight;
    setHeight1(currHeight)
  }


  useEffect(() => {
    if (count >= 3) {
      setCount(0);
      if (curFoto.index === fotos.length - 1) {
        setCurFoto({ url: fotos[0], index: 0 });
      } else {
        setCurFoto({ url: fotos[curFoto.index + 1], index: curFoto.index + 1 });
      }
    }
    const timer = setTimeout(() => setCount(count + 1), 1e3);
    return () => clearTimeout(timer);
  }, [count]);

  function excelDateToJSDate(excelDate) {
    var date = new Date(Math.round((excelDate - (25567 + 1)) * 86400 * 1000));
    var year = date.getFullYear();
    var month = months[date.getMonth()];
    var day = date.getDate();
    // var converted_date = date.toISOString().split("T")[0];
    // return converted_date;
    return { year: year, month: month, day: day };
  }

  //   useEffect(() => {
  //     let dataArr = [...data];
  //     dataArr.splice(0, 1);
  //     dataArr.splice(0, 1);

  //     console.log(dataArr);
  //     dataArr.map((doc) => {
  //       let date = excelDateToJSDate(doc.A);
  //       console.log(date);
  //       db.collection("calender")
  //         .doc("2022")
  //         .collection(`${date.month}`)
  //         .doc(`${date.day}`)
  //         .set({
  //           available: true,
  //           day: date.day,
  //           month: date.month,
  //           year: 2022,
  //           price: +doc.C,
  //         })
  //         .catch((err) => console.log(err));
  //     });
  //   }, []);

  useEffect(() => {
    // let months = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];
    // var today = new Date();
    // var year = today.getFullYear();
    // var month = months[today.getMonth()];
    // var day = today.getDate();
    // console.log(year, month, day);
    // for (let i = 1; i < 31; i++) {
    //   db.collection("calender")
    //     .doc("2022")
    //     .collection("Oktober")
    //     .doc(`${i}`)
    //     .update({ available: true })
    //     .catch((err) => console.log(err));
    // }
    // for (let j = 1; j < 31; j++) {
    //   db.collection("calender").doc("2022").collection("September").doc(`${j}`).update({ available: true });
    // }
    let elm = ReactDOMServer.renderToString(<Mail1 />);
    console.log(elm);
    // db.collection("mail")
    //   .add({
    //     to: "hiddegre@gmail.com",
    //     message: {
    //       subject: "Hello from Firebase!",
    //       text: "This is the plaintext section of the email body.",
    //       html: `This is the <code><!DOCTYPE html><html><body>${elm}</body></html></code> section of the email body.`,
    //     },
    //   })
    //   .then(() => console.log("Queued email for delivery!"));
    // let elm = ReactDOMServer.renderToString(<Mail1 />);
    // console.log(elm);
    // db.collection("mail")
    //   .add({
    //     to: "hiddegre@gmail.com",
    //     message: {
    //       subject: "Bedankt! Uw reservering bij Dishoek bij zee is bevestigd.",
    //       html: mailtest,
    //     },
    //   })
    //   .then(() => console.log("Queued email for delivery!"));
  }, []);

  useLayoutEffect(()=>{
    var myImg = document.querySelector("#backImg");
   
    var currHeight = myImg.clientHeight;
    setHeight1(currHeight)
  },[])

  return (
    <div className="home" style={{height:height1-130}}>
      {/* <div className="home_img_con">
        <img className="home_img_con_img" src={curFoto.url}></img>
      </div> */}
      <div className="home_img_con">
      <img id="backImg" className="home_img_con_img" style={{backgroundImage:`url(${homeFotos[homeFotosIndex]})`,transitionDelay: "500ms"}} ></img>
      </div>
      <div className="home_banner">Dishoek Bij Zee</div>
    </div>
  );
}

export default Home;
