import React, { useEffect, useState } from "react";
import "../../assets/css/SubItem.css";
import { v4 as uuidv4 } from "uuid";
import CircleIcon from "@mui/icons-material/Circle";
import { useStateValue } from "../../Stateprovider";
import { useLayoutEffect } from "react";

function Huisje({data1}) {
    const [{ lang }, dispatch] = useStateValue();
    const [data, setData] = useState(data1);

    function updateFotoIndex(indexArr, indexFoto) {
        let newArr = [...data];
        newArr[indexArr].fotoIndex = indexFoto;
        setData(newArr);
      }

      useLayoutEffect(()=>{
        setData(data1)

      },[])
  
    return (
      <div>

{data.map((doc, index) => (
        <div key={uuidv4()} id={`${index}`} className="subItem" style={{ background: `linear-gradient(to left, ${doc.bgc} 60%, white 40%) ` }}>
          <div className="subItem_con">
            <div className="subItem_imgCon">
              <img className="subItem_img" src={doc.fotos[doc.fotoIndex]}></img>
              <div className="subItem_buttons">
                {doc.fotos.length>1&&
                <div>
                {Array(doc.fotos.length).fill().map((doc,index2)=>(
                  <CircleIcon onClick={() => updateFotoIndex(index, index2)} fontSize="small" className="subItem_icon" />
               ))}
               </div>}
                
                {/* <CircleIcon onClick={() => updateFotoIndex(index, 0)} fontSize="small" className="subItem_icon" />

                <CircleIcon onClick={() => updateFotoIndex(index, 1)} fontSize="small" className="subItem_icon" />
                <CircleIcon fontSize="small" className="subItem_icon" />
                <CircleIcon fontSize="small" className="subItem_icon" /> */}
              </div>
            </div>
            <div className="subItem_bottom">
              <div className="subItem_textCon">
                <div className="subItem_title" style={{ color: doc.titcol }}>
                  {doc.title[lang]}
                </div>
                {doc.desc[lang]}
              </div>
              {/* <div className="subItem_bottom_moreCon">
                <img className="subItem_bottom_more" src={doc.handImg}></img>
              </div> */}
              <div className="subItem_links">
                {doc.links&&<div className="subItem_links_con">
                  {doc.links.map((doc) => (
                <div key={uuidv4()} className="subItem_links_elm">
                  {/* <div className="subItem_links_img">{doc.icon}</div> */}
                  <div className="subItem_links_text">{doc.text[lang]}</div>
                </div>
              ))}</div>}

              </div>
            </div>
          </div>
        </div>
      ))}
      </div>
    )
  

}
export default Huisje;