import React, { Component, useEffect, useState } from "react";
// import "./Mail.css";
export default class Mail5 extends React.Component {
  render() {
    return (
      <table style={{ width: "fit-content", marginLeft: "auto", marginRight: "auto" }}>
        <tr style={{ border: "1px solid black" }}>
          <td align="center">
            <img style={{ width: "15%", marginLeft: "auto", marginRight: "auto", border: "1px solid black" }} src="https://firebasestorage.googleapis.com/v0/b/dishoekhuisje.appspot.com/o/logo.png?alt=media&token=3ce36ff3-ec25-4b30-ac0a-ae61ddab0513"></img>
          </td>
          <th>Dishoek bij zee</th>
        </tr>
        <tr>
          <th style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>Inchecken</th>
          <th style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>Uitchecken</th>
          <th style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>Telefoonnummer</th>
        </tr>
        <tr>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>{/* ${bookDays[0].data.day} ${bookDays[0].data.month} ${bookDays[0].data.year} */}x</td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>{/* ${bookDays[bookDays.length - 1].data.day} ${bookDays[bookDays.length - 1].data.month} ${bookDays[bookDays.length - 1].data.year} */}x</td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>06-xxxxxxx</td>
        </tr>
        <tr>
          <th style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>Verblijfsduur</th>
          <th style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></th>
          <th style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>Bevestigingsnummer</th>
        </tr>
        <tr>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>{/* ${bookDays.length - 1} */}x</td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>{/* ${doc.id} */}x</td>
        </tr>
        <tr>
          <th style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>Adres</th>
          <th style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></th>
          <th style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></th>
        </tr>
        <tr>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>Westerscheldelaan 17; 4371 PP; Nederland</td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
        </tr>
        <tr>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
        </tr>
        <tr>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>
            Beste
            {/* ${doc.payer.name.given_name} ${doc.payer.name.surname} */}
          </td>
        </tr>
        <tr>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
        </tr>
        <tr>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>Het is ons een genoegen u te verwelkomen in het vakantiehuis Dishoek bij zee. We kijken ernaar uit om van uw verblijf een onvergetelijke ervaring te maken. Hieronder vindt u de informatie waarvan wij denken dat deze nuttig zal zijn:</td>
        </tr>
        <tr>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
        </tr>
        <tr>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>Mocht u hulp nodig hebben, neem dan gerust rechtstreeks contact met mij op via whatsapp 06- xxxxxx. Wij wensen u een zeer aangenaam verblijf bij ons.</td>
        </tr>
        <tr>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
        </tr>
        <tr>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>Hartelijke groeten,</td>
        </tr>
        <tr>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}></td>
        </tr>
        <tr>
          <td style={{ marginLeft: "auto", marginRight: "auto", width: "30%", padding: "10px" }}>Dishoek bij zee</td>
        </tr>
      </table>
    );
  }
}
