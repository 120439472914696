import React, { useState, useEffect } from "react";
import "../../assets/css/AccountSettings.css";
import { db } from "../../firebase";
import { useStateValue } from "../../Stateprovider";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
function AccountSettings(props) {
  const [{ user, profile }, dispatch] = useStateValue();
  const [profileData, setProfileData] = useState();
  const [showUpdate1, setShowUpdate1] = useState(false);
  const [showUpdate2, setShowUpdate2] = useState(false);
  const [showUpdate3, setShowUpdate3] = useState(false);
  const [reload, setReload] = useState(false);
  const history = useHistory();

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  useEffect(() => {
    
    if (user?.email&&user!=null) {
      db.collection("users")
        .where("email", "==", user?.email)
        .get()
        .then((docs) => {
          docs.docs.map((doc) => {
            setProfileData(doc.data());
            console.log(doc.data());
          });
        })
        .catch((err) => console.log(err));
    } else {
      history.push("/login");
    }
  }, [reload,user]);

  function updateProfileEmail(e) {
    let newArr = { ...profileData };
    newArr.email = e.target.value;
    setProfileData(newArr);
  }
  function updateProfileUsername(e) {
    let newArr = { ...profileData };
    newArr.username = e.target.value;
    setProfileData(newArr);
  }
  function updateProfileTel(e) {
    let newArr = { ...profileData };
    newArr.tel = e.target.value;
    setProfileData(newArr);
  }
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };
  function handleEmail() {
    if (validateEmail(profileData?.email)) {
      db.collection("users").doc(profile?.username).update({
        email: profileData?.email,
      });
    } else {
      setReload(!reload);
    }

    setShowUpdate1(false);
  }
  function handleUsername() {
    db.collection("users").doc(profile?.username).update({
      username: profileData?.username,
    });

    setShowUpdate2(false);
  }
  const validateTel = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };
  function handleTel() {
    db.collection("users").doc(profile?.username).update({
      tel: profileData?.tel,
    });

    setShowUpdate3(false);
  }

  return (
    <div className="acc">
      {!showUpdate1 ? (
        <div key={uuidv4()} className="acc_grid">
          <div className="acc_grid_elm">
            <div className="acc_grid_elm_l">
              <div className="acc_grid_elm_l_t">Email</div>
              <div className="acc_grid_elm_l_b">{profileData?.email}</div>
            </div>
            {/* <div onClick={() => setShowUpdate1(true)} className="acc_grid_elm_r">
              Aanpassen
            </div> */}
          </div>
        </div>
      ) : (
        <div className="acc_grid">
          <div className="acc_grid_elm">
            <div className="acc_grid_elm_l">
              <div className="acc_grid_elm_l_t">Email</div>
              <input className="acc_grid_elm_l_b_inp " onChange={(e) => updateProfileEmail(e)} value={profileData?.email}></input>
              <button onClick={() => handleEmail()} className="acc_btn">
                Update
              </button>
            </div>
            <div onClick={() => handleEmail()} className="acc_grid_elm_r">
              Annuleren
            </div>
          </div>
        </div>
      )}

      {!showUpdate2 ? (
        <div key={uuidv4()} className="acc_grid">
          <div className="acc_grid_elm">
            <div className="acc_grid_elm_l">
              <div className="acc_grid_elm_l_t">Username</div>
              <div className="acc_grid_elm_l_b">{profileData?.username}</div>
            </div>
            <div onClick={() => setShowUpdate2(true)} className="acc_grid_elm_r">
              Aanpassen
            </div>
          </div>
        </div>
      ) : (
        <div className="acc_grid">
          <div className="acc_grid_elm">
            <div className="acc_grid_elm_l">
              <div className="acc_grid_elm_l_t">Username</div>
              <input className="acc_grid_elm_l_b_inp " onChange={(e) => updateProfileUsername(e)} value={profileData?.username}></input>
              <button onClick={() => handleUsername()} className="acc_btn">
                Update
              </button>
            </div>
            <div onClick={() => handleUsername()} className="acc_grid_elm_r">
              Annuleren
            </div>
          </div>
        </div>
      )}

      {!showUpdate3 ? (
        <div key={uuidv4()} className="acc_grid">
          <div className="acc_grid_elm">
            <div className="acc_grid_elm_l">
              <div className="acc_grid_elm_l_t">Mobile</div>
              <div className="acc_grid_elm_l_b">{profileData?.tel}</div>
            </div>
            <div onClick={() => setShowUpdate3(true)} className="acc_grid_elm_r">
              Aanpassen
            </div>
          </div>
        </div>
      ) : (
        <div className="acc_grid">
          <div className="acc_grid_elm">
            <div className="acc_grid_elm_l">
              <div className="acc_grid_elm_l_t">Mobiel</div>
              <input className="acc_grid_elm_l_b_inp " onChange={(e) => updateProfileTel(e)} value={profileData?.tel}></input>
              <button onClick={() => handleTel()} className="acc_btn">
                Update
              </button>
            </div>
            <div onClick={() => handleTel()} className="acc_grid_elm_r">
              Annuleren
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AccountSettings;
