import React, { useEffect, useState } from "react";
import "../../assets/css/ReserverenMain.css";
import Calender from "./Calender";
import { useStateValue } from "../../Stateprovider";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveIcon from "@mui/icons-material/Remove";
import { useHistory } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";

function ReserverenMain(props) {
  const [{ bookDays, guests, curMinStay ,lang}, dispatch] = useStateValue();
  const history = useHistory();
  const [serviceCost, setServiceCost] = useState(100);
  const [adultsAmount, setAdultsAmount] = useState(1);
  const [kidsAmount, setKidsAmount] = useState(0);
  const [babyAmount, setBabyAmount] = useState(0);
  const [petsAmount, setPetsAmount] = useState(0);

  const [maxGuests, setMaxGuests] = useState(4);
  const [maxAdults, setMaxAdults] = useState(4);
  const [maxKids, setMaxKids] = useState(2);
  const [maxBaby, setMaxBaby] = useState(1);
  const [maxPets, setMaxPets] = useState(2);
  const [maxAdultsKids, setMaxAdultsKids] = useState(4);

  // const [kinderbed,setKinderBed]=useState(false);
  // const [handDoeken, setHandDoeken] = useState(false);
  // const [linnenPakket, setLinnenPakket] = useState(false);

  const [kinderBed, setKinderBed] = useState(false);
  const [handDoeken, setHandDoeken] = useState(false);
  const [linnenPakket, setLinnenPakket] = useState(false);
  const [bedden1, setBedden1] = useState(false);

  const [eindSchoonMaak, setEindSchoonMaak] = useState(50);
  const [honden, setHonden] = useState(10);
  const [toeristenBelasting, setToeristenBelasting] = useState(2);

  const [months, setMonths] = useState(["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"]);
  const [total, setTotal] = useState(0);
  const [totalBook, setTotalBook] = useState(0);
  
  const [textUnderCal,setTextUnderCal]=useState([
    "het strandhuis is geschikt voor maximaal 4 personen. In verband met de beschikbare ruimte is er een inklapbaar kinderbedje beschikbaar voor kinderen t/m 3 jaar.",
    "Das Strandhaus ist für maximal 4 Personen geeignet. Aufgrund des begrenzten Platzes steht ein klappbares Kinderbett für Kinder bis einschließlich 3 Jahre zur Verfügung.",
    "The beach house is suitable for up to 4 people. Due to limited space, a foldable crib is available for children up to 3 years old."

  ])
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  useEffect(() => {
    let newTotal = 0;
    let newTotalBook = 0;

    for (let i = 0; i < bookDays.length - 1; i++) {
      newTotal += bookDays[i].data.price;
      newTotalBook += bookDays[i].data.price;
    }
    if(bookDays.length>=1){
      newTotal += eindSchoonMaak + toeristenBelasting * (bookDays.length - 1) * (adultsAmount + kidsAmount + babyAmount) + petsAmount * honden;

    }else{
      newTotal += eindSchoonMaak + toeristenBelasting  * (adultsAmount + kidsAmount + babyAmount) + petsAmount * honden;

    }
    
    setTotal(newTotal);
    setTotalBook(newTotalBook);
  }, [bookDays]);

  function reserveerAanvraag() {
    console.log("aanvragen booking");
    console.log(bookDays)
    dispatch({
      type: "SET_GUEST_INFO",
      guestInfo: { adults: adultsAmount, kids: kidsAmount, babys: babyAmount, pets: petsAmount, kinderBed: kinderBed, handDoeken: handDoeken, linnen: linnenPakket === 0 ? false : true, bedden1: bedden1 },
    });

    dispatch({
      type: "SET_PRICE_DETAILS",
      priceDetails: { bedden1: bedden1, linnenPakket: linnenPakket, kinderBed: kinderBed, handDoeken: handDoeken, nachten: totalBook, toeristenBelasting: toeristenBelasting * (bookDays.length - 1) * (adultsAmount + kidsAmount + babyAmount), eindSchoonMaak: eindSchoonMaak, pets: petsAmount * honden },
    });
    history.push("/reserveren/aanvragen")
   
  }

  useEffect(() => {
    let newMaxAdults = maxAdultsKids - kidsAmount - babyAmount;
    let newMaxKids = maxAdultsKids - adultsAmount - babyAmount;
    let newMaxBabys = maxGuests - adultsAmount - kidsAmount;
    setMaxAdults(newMaxAdults);
    setMaxKids(newMaxKids);
    setMaxBaby(newMaxBabys);
  }, [adultsAmount, kidsAmount, babyAmount]);

  return (
    <div className="resm" style={{ background: `linear-gradient(to left, #D6E8C6 60%, white 40%) ` }}>
      <Calender />

      <div className="resm_info">{textUnderCal[lang]}</div>
      <div className="resm_bottom">
        <div className="resm_gasten">
          <div className="resm_gasten_element">
            <div className="resm_gasten_element_l">
              <div className="resm_gasten_element_l_top">Volwassenen</div>
              <div className="resm_gasten_element_l_bottom">18 jaar en ouder</div>
            </div>
            <div className="resm_gasten_element_r">
              {adultsAmount > 1 ? (
                <RemoveIcon
                  fontSize="large"
                  onClick={() => {
                    if (adultsAmount > 1) {
                      setAdultsAmount(adultsAmount - 1);
                    }
                  }}
                  className="app_icon"
                />
              ) : (
                <RemoveIcon
                  color="disabled"
                  fontSize="large"
                  onClick={() => {
                    if (adultsAmount > 1) {
                      setAdultsAmount(adultsAmount - 1);
                    }
                  }}
                  className="app_icon"
                />
              )}
              {adultsAmount}

              {adultsAmount < maxAdults ? (
                <AddCircleOutlineIcon
                  fontSize="large"
                  onClick={() => {
                    if (adultsAmount < maxAdults) {
                      setAdultsAmount(adultsAmount + 1);
                    }
                  }}
                  className="app_icon"
                />
              ) : (
                <AddCircleOutlineIcon
                  color="disabled"
                  fontSize="large"
                  onClick={() => {
                    if (adultsAmount < maxAdults) {
                      setAdultsAmount(adultsAmount + 1);
                    }
                  }}
                  className="app_icon"
                />
              )}
            </div>
          </div>

          <div className="resm_gasten_element">
            <div className="resm_gasten_element_l">
              <div className="resm_gasten_element_l_top">Kinderen</div>
              <div className="resm_gasten_element_l_bottom">4 t/m 17 jaar</div>
            </div>
            <div className="resm_gasten_element_r">
              {kidsAmount > 0 ? (
                <RemoveIcon
                  fontSize="large"
                  onClick={() => {
                    if (kidsAmount > 0) {
                      setKidsAmount(kidsAmount - 1);
                    }
                  }}
                  className="app_icon"
                />
              ) : (
                <RemoveIcon
                  color="disabled"
                  fontSize="large"
                  onClick={() => {
                    if (kidsAmount > 0) {
                      setKidsAmount(kidsAmount - 1);
                    }
                  }}
                  className="app_icon"
                />
              )}

              {kidsAmount}
              {kidsAmount < maxKids ? (
                <AddCircleOutlineIcon
                  fontSize="large"
                  onClick={() => {
                    if (kidsAmount < maxKids) {
                      setKidsAmount(kidsAmount + 1);
                    }
                  }}
                  className="app_icon"
                />
              ) : (
                <AddCircleOutlineIcon
                  color="disabled"
                  fontSize="large"
                  onClick={() => {
                    if (kidsAmount < maxKids) {
                      setKidsAmount(kidsAmount + 1);
                    }
                  }}
                  className="app_icon"
                />
              )}
            </div>
          </div>

          <div className="resm_gasten_element">
            <div className="resm_gasten_element_l">
              <div className="resm_gasten_element_l_top">Baby's</div>
              <div className="resm_gasten_element_l_bottom">0 t/m 3 jaar</div>
            </div>
            <div className="resm_gasten_element_r">
              {babyAmount > 0 ? (
                <RemoveIcon
                  fontSize="large"
                  onClick={() => {
                    if (babyAmount > 0) {
                      setBabyAmount(babyAmount - 1);
                    }
                  }}
                  className="app_icon"
                />
              ) : (
                <RemoveIcon
                  color="disabled"
                  fontSize="large"
                  onClick={() => {
                    if (babyAmount > 0) {
                      setBabyAmount(babyAmount - 1);
                    }
                  }}
                  className="app_icon"
                />
              )}

              {babyAmount}
              {babyAmount < maxBaby ? (
                <AddCircleOutlineIcon
                  fontSize="large"
                  onClick={() => {
                    if (babyAmount < maxBaby) {
                      setBabyAmount(babyAmount + 1);
                    }
                  }}
                  className="app_icon"
                />
              ) : (
                <AddCircleOutlineIcon
                  color="disabled"
                  fontSize="large"
                  onClick={() => {
                    if (babyAmount < maxBaby) {
                      setBabyAmount(babyAmount + 1);
                    }
                  }}
                  className="app_icon"
                />
              )}
            </div>
          </div>

          <div className="resm_gasten_element">
            <div className="resm_gasten_element_l">
              <div className="resm_gasten_element_l_top">Honden</div>
              <div className="resm_gasten_element_l_bottom"></div>
            </div>
            <div className="resm_gasten_element_r">
              {petsAmount > 0 ? (
                <RemoveIcon
                  fontSize="large"
                  onClick={() => {
                    if (petsAmount > 0) {
                      setPetsAmount(petsAmount - 1);
                    }
                  }}
                  className="app_icon"
                />
              ) : (
                <RemoveIcon
                  color="disabled"
                  fontSize="large"
                  onClick={() => {
                    if (petsAmount > 0) {
                      setPetsAmount(petsAmount - 1);
                    }
                  }}
                  className="app_icon"
                />
              )}

              {petsAmount}
              {petsAmount < maxPets ? (
                <AddCircleOutlineIcon
                  fontSize="large"
                  onClick={() => {
                    if (petsAmount < maxPets) {
                      setPetsAmount(petsAmount + 1);
                    }
                  }}
                  className="app_icon"
                />
              ) : (
                <AddCircleOutlineIcon
                  color="disabled"
                  fontSize="large"
                  onClick={() => {
                    if (petsAmount < maxPets) {
                      setPetsAmount(petsAmount + 1);
                    }
                  }}
                  className="app_icon"
                />
              )}
            </div>
          </div>
        </div>

        <div className="resm_gasten">
          <div className="resm_gasten_element">
            <div className="resm_gasten_element_l">
              <div className="resm_gasten_element_l_top">handdoeken pakket</div>
            </div>
            <div className="resm_gasten_element_r">
              <CheckIcon fontSize="large" onClick={() => setHandDoeken(!handDoeken)} className={`app_icon ${handDoeken ? "res_icon_t" : "res_icon_f"}`} />
            </div>
          </div>

          <div className="resm_gasten_element">
            <div className="resm_gasten_element_l">
              <div className="resm_gasten_element_l_top">Linnen pakket</div>
            </div>
            <div className="resm_gasten_element_r">
              <CheckIcon fontSize="large" onClick={() => setLinnenPakket(!linnenPakket)} className={`app_icon ${linnenPakket ? "res_icon_t" : "res_icon_f"}`} />
            </div>
          </div>

          <div className="resm_gasten_element">
            <div className="resm_gasten_element_l">
              <div className="resm_gasten_element_l_top">Kinderbedje</div>
            </div>
            <div className="resm_gasten_element_r">
              <CheckIcon fontSize="large" onClick={() => setKinderBed(!kinderBed)} className={`${kinderBed ? "res_icon_t" : "res_icon_f"} app_icon`} />
            </div>
          </div>

          <div className="resm_gasten_element">
            <div className="resm_gasten_element_l">
              <div className="resm_gasten_element_l_top"> 1x 2 persoonsbed</div>
              <div className="resm_gasten_element_l_bottom">Bedden 1e verdieping</div>
            </div>
            <div className="resm_gasten_element_r">
              <CheckIcon fontSize="large" onClick={() => setBedden1(!bedden1)} className={`resm_icon app_icon ${bedden1 ? "res_icon_t" : "res_icon_f"}`} />
            </div>
          </div>

          <div className="resm_gasten_element">
            <div className="resm_gasten_element_l">
              <div className="resm_gasten_element_l_top"> 2x 1 persoonsbed</div>
              <div className="resm_gasten_element_l_bottom">Bedden 1e verdieping</div>
            </div>
            <div className="resm_gasten_element_r">
              <CheckIcon fontSize="large" onClick={() => setBedden1(!bedden1)} className={`resm_icon app_icon ${bedden1 ? "res_icon_f" : "res_icon_t"}`} />
            </div>
          </div>
        </div>

        <div className="resm_btn_container">
          <div className="resm_btn_container_items">
            {bookDays.length < curMinStay + 1 && <p className="resm_btn_textBelow">Reserveer voor minimaal {curMinStay} nachten</p>}
            {bookDays.length >= curMinStay + 1 ? (
              <div  onClick={() => {reserveerAanvraag()
                ;}} className="resm_btn resm_btn_active">
                Reserveer
              </div>
            ) : (
              <div className="resm_btn">Reserveer</div>
            )}
            <p className="resm_btn_textBelow">Er wordt nog niets in rekening gebracht</p>
            <div className="resm_btn_container_item">
              <div className="resm_btn_container_item_l">
                {bookDays.length > 1 && bookDays.length - 1}
                nachten
              </div>
              <div className="resm_btn_container_item_r">€{totalBook}</div>
            </div>
            <div className="resm_btn_container_item">
              <div className="resm_btn_container_item_l">Eindschoonmaak</div>
              <div className="resm_btn_container_item_r">€{eindSchoonMaak}</div>
            </div>
            <div className="resm_btn_container_item">
              <div className="resm_btn_container_item_l">Toeristenbelasting (€{toeristenBelasting} p.p.p.n.)</div>
              <div className="resm_btn_container_item_r">€{bookDays.length - 1 > 0 ? toeristenBelasting * (bookDays.length - 1) * (adultsAmount + kidsAmount + babyAmount) : toeristenBelasting * (adultsAmount + kidsAmount + babyAmount)}</div>
            </div>

            {kinderBed && (
              <div className="resm_btn_container_item">
                <div className="resm_btn_container_item_l">Kinderbedje</div>
                <div className="resm_btn_container_item_r">Gratis</div>
              </div>
            )}

            {handDoeken && (
              <div className="resm_btn_container_item">
                <div className="resm_btn_container_item_l">Handdoeken pakket</div>
                <div className="resm_btn_container_item_r">Gratis</div>
              </div>
            )}

            {linnenPakket && (
              <div className="resm_btn_container_item">
                <div className="resm_btn_container_item_l">Linnen pakket</div>
                <div className="resm_btn_container_item_r">Gratis</div>
              </div>
            )}

            {petsAmount > 0 && (
              <div className="resm_btn_container_item">
                <div className="resm_btn_container_item_l">Honden (max. 2)</div>
                <div className="resm_btn_container_item_r">€{honden}</div>
              </div>
            )}

            <div className="resm_btn_container_total">
              <div className="resm_btn_container_total_l">Total</div>
              <div className="resm_btn_container_total_r">€{total}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReserverenMain;
